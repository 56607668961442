import React, { Component } from 'react'
import { auth } from '../config/firebase-config'
import { getUserData, UserData } from '../query/db/user'


interface UserContextType {
  user: UserData | null,
  loadingUser: boolean,
  triggerLoginCount: number,
  setUser: (u:UserData | null) => void,
  setLoading: (b:boolean) => void,
  refreshUser: () => void,
  triggerLogin: () => void,
}

const UserContext = React.createContext<UserContextType>(
  { 
    user: null,
    loadingUser: false,
    triggerLoginCount: 0,
    setUser: (u:UserData|null) => {}, 
    setLoading: (b:boolean) => {},
    refreshUser: ()=>{},
    triggerLogin: ()=>{}
  }
)

class UserProvider extends Component {
  // Context state
  setUser = (u: UserData | null) => { this.setState({user: u})}
  setLoading = (l: boolean) => { this.setState({loadingUser: l})}
  triggerLogin = () => { this.setState({triggerLoginCount: this.state.triggerLoginCount+1}) }
  refreshUser = () => { this.setState({user: {...this.state.user}})}

  state:UserContextType = {
    user: null,
    loadingUser: true,
    triggerLoginCount: 0,
    setUser: this.setUser,
    setLoading: this.setLoading,
    refreshUser: this.refreshUser,
    triggerLogin: this.triggerLogin,
  }

  constructor(props:any) {
    super(props)
    auth.onAuthStateChanged((u) => {
      if (u) {
        getUserData(u).then((ud) => {
          this.setUser(ud)
        }).finally(() => {
          this.setLoading(false)
        })
      } else {
          this.setUser(null)
          this.setLoading(false)
      }
    })
    if (auth.currentUser) {
      getUserData(auth.currentUser).then((ud) => {
        this.setUser(ud)
      })
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type 'Readon... Remove this comment to see the full error message
    const { children } = this.props
    const { user, loadingUser, triggerLoginCount: triggerLoginInc } = this.state
    const { setUser, setLoading, refreshUser, triggerLogin } = this

    return (
      <UserContext.Provider
        value={{
          user,
          loadingUser,
          triggerLoginCount: triggerLoginInc,
          setUser,
          setLoading,
          refreshUser,
          triggerLogin,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export { UserProvider, UserContext }

export function hasDisplayName(user:UserData|null) {
  return user?.user.displayName !== null && user?.user.displayName !== undefined
}