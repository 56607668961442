import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import Card from '@mui/material/Card'
import { SxProps } from '@mui/material/styles'
import { useNavigate } from "react-router-dom";
import { RecMap } from "../query/db/map";
import { mapBasePath } from "../pages/map";
import { Typography } from '@mui/material'

const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const dateLocale = "en-US"

const cardStyle = {
    boxShadow: 'none',
    cursor: 'pointer',
}

export const boxStyle:SxProps = {
    height: '150px',
    borderRadius: "16px",
    borderWidth: "1px",
    borderColor: "#f2f2f2"
}

export function SkeletonMapTile() {
    return (
        <Card sx={cardStyle}>
            <Skeleton variant='rectangular' sx={boxStyle}/>
            <ImageListItemBar 
                title={<Skeleton variant='text'></Skeleton>}
                subtitle={<Skeleton variant='text'></Skeleton>}
                position="below"
                sx={{ paddingLeft: "8px"}}
            />
        </Card>
    )
}

export default function MapTile({
    map
}:{map: RecMap}) {
    const navigate = useNavigate();
    let formattedCreatedAt = new Date(new Date(map.data.createdAt))
        .toLocaleString(dateLocale, options)
    
    let navigateToMap = () => {
        let mapPath = mapBasePath + map.id
        navigate(mapPath)
    }
    return (
        <Card onClick={navigateToMap} sx={cardStyle}>
            <Box sx={{...boxStyle, background: map.data.backgroundGradient, backgroundImage: map.data.backgroundImg}}/>
            <ImageListItemBar 
                title={<Typography variant='subtitle1' sx={{whiteSpace: "normal"}}>{map.data.mapName}</Typography>}
                subtitle={formattedCreatedAt}
                position="below"
                sx={{ paddingLeft: "8px"}}
            />
        </Card>
    )
}