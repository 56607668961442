import { getAreaOfPolygon, getCenterOfBounds, getDistance } from "geolib";
import { Place, RecMap } from "../db/map";

export interface SearchResult {
    place: Place,
    itemId: string,
}

export interface Region {
    northLat: number;
    southLat: number;
    westLng: number;
    eastLng: number;
}

export class Region implements Region {
    constructor(northLat:number, southLat:number, westLng:number, eastLng:number) {
        this.northLat = northLat
        this.southLat = southLat
        this.westLng = westLng
        this.eastLng = eastLng
    }
}

export function regionsEqual(r1:Region|null, r2:Region|null) {
    return r1?.northLat === r2?.northLat
        && r1?.eastLng === r2?.eastLng
        && r1?.southLat === r2?.southLat
        && r1?.westLng === r2?.westLng
}

export function regionsNear(r1:Region|null, r2:Region|null) {
    if (!r1 || !r2) {
        return false
    }
    if (r1 === r2) {
        return true
    }
    let c1 = getCenterOfBounds([
        { latitude: r1?.northLat, longitude: r1?.eastLng },
        { latitude: r1?.northLat, longitude: r1?.westLng },
        { latitude: r1?.southLat, longitude: r1?.eastLng },
        { latitude: r1?.southLat, longitude: r1?.westLng },
    ]);
    let c2 = getCenterOfBounds([
        { latitude: r2?.northLat, longitude: r2?.eastLng },
        { latitude: r2?.northLat, longitude: r2?.westLng },
        { latitude: r2?.southLat, longitude: r2?.eastLng },
        { latitude: r2?.southLat, longitude: r2?.westLng },
    ]);
    // Consider near if they are within X meters
    return getDistance(c1, c2) < 5000
}

export function regionsSimilarSize(r1:Region|null, r2:Region|null) {
    if (!r1 || !r2) {
        return false
    }
    if (r1 === r2) {
        return true
    }
    let a1 = getAreaOfPolygon([
        { latitude: r1?.northLat, longitude: r1?.eastLng },
        { latitude: r1?.northLat, longitude: r1?.westLng },
        { latitude: r1?.southLat, longitude: r1?.eastLng },
        { latitude: r1?.southLat, longitude: r1?.westLng },
    ]);
    let a2 = getAreaOfPolygon([
        { latitude: r2?.northLat, longitude: r2?.eastLng },
        { latitude: r2?.northLat, longitude: r2?.westLng },
        { latitude: r2?.southLat, longitude: r2?.eastLng },
        { latitude: r2?.southLat, longitude: r2?.westLng },
    ]);
    // Consider near if they are within X meters^2 of eachother
    return Math.abs(a1 - a2) < 20000
}