import { useContext, useEffect, useState } from "react";
import { SavedPlace } from "../query/db/map";
import { UserContext } from "../context/userContext";
import LinearProgress from "@mui/material/LinearProgress"
import ListItemButton from '@mui/material/ListItemButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { CellMeasurer, CellMeasurerCache, List, ListRowProps } from 'react-virtualized'
import Stack from '@mui/material/Stack'

import useWidth from "../hooks/windowWidth";
import TextField from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from '@mui/material/IconButton'
import Close from '@mui/icons-material/Close'
import { SearchResult } from "../query/search/common";
import { Typography } from "@mui/material";
import { coreTheme } from "../styles/themes";

export type selectPlaceFn = (index:number, place:SearchResult|SavedPlace)=>void
export type onFilterPlacesFn = (keepPlaces:SavedPlace[])=>void

const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 75
  });

function anyMatch(tags:string[], m:string) {
    if (!tags) {
        return false
    }
    for (let i = 0; i < tags.length; i++) {
        const s = tags[i];
        if (s.toLowerCase().includes(m)) {
            return true
        }
    }
    return false
}

export default function PlacesList({places, onSelectItem, onFilterPlaces, inDrawer}:{places:SavedPlace[], onSelectItem:selectPlaceFn, onFilterPlaces:onFilterPlacesFn, inDrawer:boolean}) {

    const userCtx = useContext(UserContext)
    const width = useWidth()

    let [selectedPlaceId, setSelectedPlaceId] = useState("")
    let [filterInputField, setFilterInputField] = useState("")
    let [debouncedFilter, setDebouncedFilter] = useState<string|null>(null)
    let [filteredPlaces, setFilteredPlaces] = useState<SavedPlace[]>(places)

    useEffect(() => {
        cache.clearAll()
    }, [width])

    useEffect(() => {
        const timeout = setTimeout(() => setDebouncedFilter(filterInputField), 400);
        return () => {
          clearTimeout(timeout);
        };
    }, [filterInputField])

    useEffect(() => {
        if (debouncedFilter) {
            setSelectedPlaceId("")
            let filteredPlaces = places.filter(p => {
                if (debouncedFilter) {
                    let matches = (p.place.name && p.place.name.toLowerCase().includes(debouncedFilter.toLocaleLowerCase()))
                        || (p.place.notes && p.place.notes.toLowerCase().includes(debouncedFilter.toLocaleLowerCase()))
                        || (p.place.address && p.place.address.toLowerCase().includes(debouncedFilter.toLocaleLowerCase()))
                        || anyMatch(p.place.tags, debouncedFilter.toLocaleLowerCase())
                        || anyMatch(p.place.pinTags, debouncedFilter.toLocaleLowerCase())
                    return matches
                }
                return true
            })
            setFilteredPlaces(filteredPlaces)
        } else {
            setFilteredPlaces(places)
        }
    }, [debouncedFilter, places])

    useEffect(() => {
        onFilterPlaces(filteredPlaces)
    }, [filteredPlaces, onFilterPlaces])


    let renderRow = ({ index, key, style, parent }:ListRowProps) => {
        return (
            <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}>
            {() => {
                let place = filteredPlaces[index]
        
                let selectItem = () => {
                    onSelectItem(index, place)
                    setSelectedPlaceId(place.id)
                }
        
                return (
                    <ListItem style={style} key={index} component="div" disablePadding>
                    <ListItemButton onClick={selectItem} selected={selectedPlaceId === place.id}>
                        <ListItemText primary={place.place.name} secondary={place.place.address} />
                    </ListItemButton>
                    </ListItem>
                );
            }}
        </CellMeasurer>
        )
    }
    
    if (userCtx.loadingUser) {
        return (
            <LinearProgress />
        )
    }

    return (
        <Stack flex={1} display={"flex"}>
            <TextField 
                fullWidth
                hiddenLabel={true}
                variant="filled"
                placeholder="Search places"
                onChange={e => setFilterInputField(e.target.value)}
                value={filterInputField}
                sx={{paddingRight: (inDrawer ? "0px" : "16px")}}
                InputProps={{
                    sx: { borderRadius: '12px', "& fieldset": { border: 'none' }},
                    disableUnderline: true,
                    startAdornment: (<InputAdornment position="start" sx={{marginRight:"4px"}}><SearchIcon sx={{paddingLeft:"2px"}}/></InputAdornment>),
                    endAdornment: 
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="search and filter places"
                          onMouseDown={(e) => {
                            setFilterInputField("")
                            e.preventDefault()
                        }}
                        >
                          {debouncedFilter ? <Close /> : <></>}
                        </IconButton>
                      </InputAdornment>
                }}
            >
            </TextField>
            <Box flex={1} display={"flex"}>
                {places.length === 0 && <Typography paddingTop={"8px"} variant="subtitle2" color={coreTheme.palette.grey[500]}>No places on map</Typography>}
                {(places.length !== 0 && filteredPlaces.length === 0) && <Typography paddingTop={"8px"} variant="subtitle2" color={coreTheme.palette.grey[500]}>No results found</Typography>}
                <AutoSizer >
                {({ height, width }: Size) => (
                <List
                    height={height}
                    width={width}
                    id="place-list-vl"
                    style={{overflowY:"auto", overflowX:"auto"}}
                    deferredMeasurementCache={cache}
                    rowHeight={cache.rowHeight} 
                    rowRenderer={renderRow}
                    rowCount={filteredPlaces.length}
                    scrollToIndex={places.findIndex(p => p.id === selectedPlaceId)}
                    overscanRowCount={5}
                    />
                )}
            </AutoSizer>
            </Box>
        </Stack>
    )
}