import { useState, useEffect, useContext } from 'react'

import { RecMap } from "../query/db/map";
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LockIcon from '@mui/icons-material/Lock';
import { UserContext } from '../context/userContext';
import { getUserDisplayInfo } from '../query/db/user';

export default function MapNameTitle({map}:{map:RecMap}) {
    let userCtx = useContext(UserContext)

    let [mapTitle, setMapTitle] = useState(map.data.mapName)
    let [lockIcon, setLockIcon] = useState(map.data.permissions === 'private')
    let [bgGradient, setBgGradient] = useState(map.data.backgroundGradient)
    let [ownerDisplayName, setOwnerDisplayName] = useState<string|null>(null)

    useEffect(() => {
        if (map.data.owner === userCtx.user?.user.uid) {
            setOwnerDisplayName(userCtx.user.user.displayName)
            return
        }
        getUserDisplayInfo(map.data.owner).then((info) => {
            setOwnerDisplayName(info.displayName)
        })
    }, [userCtx.user, userCtx.user?.user, userCtx.user?.user.displayName, map.data.owner])

    useEffect(() => {
        setMapTitle(map.data.mapName)
    }, [map, map.data, map.data.mapName])

    useEffect(() => {
        setLockIcon(map.data.permissions === 'private')
    }, [map, map.data, map.data.permissions])

    useEffect(() => {
        setBgGradient(map.data.backgroundGradient)
    }, [map, map.data, map.data.backgroundGradient])

    return (<>
        <Stack direction={"row"} padding={"2px"} alignItems={"center"}>
            <Box sx={{background: bgGradient, height:"40px", width: "40px", marginRight: "8px", borderRadius:"12px"}}/>
            <Stack direction={"column"}>
                <Typography variant="h6" paddingBottom={"4px"} fontWeight="bold" margin="0px">
                    {mapTitle}
                </Typography>
                <Typography variant="subtitle2" paddingBottom={"2px"} sx={{ alignItems:"center"}}>
                {lockIcon && <LockIcon sx={{height: "12px", width:"12px", paddingTop:"3px"}}/> }
                {ownerDisplayName}
                </Typography>
            </Stack>
        </Stack>
    </>)
}