import { ref, uploadBytes, deleteObject, getBlob } from "firebase/storage";
import { firebaseStorage } from "../../config/firebase-config";
import { generate } from "randomstring";
import { SavedPlace } from "../db/map";
import localForage from "localforage";

export async function uploadImage(mapId:string, place:SavedPlace, image:File):Promise<string> {
    const ext = image.name.split('.').pop() || 'jpg'
    const imageId = generate(16) + '.' + ext
    const storageRef = ref(firebaseStorage, 'images/'   + mapId + '/' + place.id + '/' + imageId);
    await uploadBytes(storageRef, image, {contentType: image.type})
    place.place.imageSrcs = [...place.place.imageSrcs, imageId]
    return imageId
}

export async function deleteImage(mapId:string, place:SavedPlace, imageId:string) {
    const storageRef = ref(firebaseStorage, 'images/'+ mapId + '/' + place.id + '/' + imageId);
    await deleteObject(storageRef)
    let newImgs:string[] = []
    place.place.imageSrcs.forEach(i => { if (i !== imageId) newImgs.push(i) })
    place.place.imageSrcs = newImgs
}

export async function getImageURL(mapId:string, placeId:string, imageURI:string) {
    let key = 'images/'+ mapId + '/' + placeId + '/' + imageURI
    let image = await localForage.getItem<Blob>(key)
    if (!image) {
        console.log("image not cached")
        const r = ref(firebaseStorage, key);
        image = (await getBlob(r))
        localForage.setItem(key, image)
    }

    return URL.createObjectURL(image)
}