import { useState, useEffect } from 'react';

export default function useWidth() {
    const [width, setWidth] = useState<number|undefined>(undefined);
    useEffect(() => {
      function handleResize() {
        let windowWidth = window.innerWidth
        setWidth(windowWidth);
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return width;
}