import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useState, useEffect } from 'react';

import MapTile, { SkeletonMapTile } from './maptile';
import { coreTheme } from '../styles/themes';
import { RecMap } from '../query/db/map';
import useWidth from '../hooks/windowWidth';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Close } from '@mui/icons-material';

export type MapListProps = {mapFetcher:()=>Promise<RecMap[]|null>, searchFilter:string}

export default function MapList(props:MapListProps) {
    let [maps, setMaps] = useState<RecMap[]|null>(null);
    let [filterInputField, setFilterInputField] = useState("")
    let [debouncedFilter, setDebouncedFilter] = useState<string|null>(null)
    let [filteredMaps, setFilteredMaps] = useState<RecMap[]|null>(maps)

    const mapFetcher = props.mapFetcher

    useEffect(() => {
        if ((!maps)) {
            mapFetcher().then(fetchedMaps => {
                if (fetchedMaps) {
                    setMaps(fetchedMaps)
                }
            })
        }
    }, [maps, mapFetcher])

    useEffect(() => {
        const timeout = setTimeout(() => setDebouncedFilter(filterInputField), 400);
        return () => {
          clearTimeout(timeout);
        };
    }, [filterInputField])

    let windowWidth = useWidth()
    
    let colCount = 4
    let fullWidthSearch = false

    if (windowWidth && windowWidth < (coreTheme.breakpoints.values.lg)) {
        colCount = 3
    }
    if (windowWidth && windowWidth < (coreTheme.breakpoints.values.md)) {
        colCount = 2
    }
    if (windowWidth && windowWidth < (coreTheme.breakpoints.values.sm)) {
        colCount = 2
        fullWidthSearch = true
    }

    useEffect(() => {
        if (debouncedFilter && maps) {
            let filteredMaps = maps.filter(m => {
                return m.data.mapName.toLowerCase().indexOf(filterInputField.toLowerCase()) >= 0
            })

            setFilteredMaps(filteredMaps)
        } else {
            setFilteredMaps(maps)
        }
    }, [debouncedFilter, maps])

    let rows = [1, 2, 3, 4].map((i: number) => <ImageListItem key={i} ><SkeletonMapTile/></ImageListItem>)
    if (maps && maps.length > 0) {
        rows = (filteredMaps ?? []).map((m: RecMap) => <ImageListItem key={m.id} ><MapTile map={m} /></ImageListItem>)
    } else if (maps !== null) {
        return <Typography>No Maps</Typography>
    }
    
    return (
        <>
        <TextField 
                variant="filled"
                fullWidth={fullWidthSearch}
                hiddenLabel={true}
                placeholder="Search maps"
                onChange={e => setFilterInputField(e.target.value)}
                value={filterInputField}
                InputProps={{
                    sx: { borderRadius: '12px', "& fieldset": { border: 'none' }},
                    disableUnderline: true,
                    startAdornment: (<InputAdornment position="start" sx={{marginRight:"4px"}}><SearchIcon sx={{paddingLeft:"2px"}}/></InputAdornment>),
                    endAdornment: 
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="search and filter maps"
                          onMouseDown={(e) => {
                            setFilterInputField("")
                            e.preventDefault()
                        }}
                        >
                          {debouncedFilter ? <Close /> : <></>}
                        </IconButton>
                      </InputAdornment>
                }}
            >
            </TextField>
        <ImageList cols={colCount} gap={Number(24)} >
            {rows}
        </ImageList>
        </>
    );
}