import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router"

export default function BottomNav() {
    const navigate = useNavigate()
    return (<Box display={"flex"} flex={1} justifyContent={"center"}>
        <Button onClick={() => navigate("/privacy")}>Privacy Policy</Button>
        <Button onClick={() => navigate("/terms")}>Terms</Button>
    </Box>)
}