import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Link as RouterLink,
  MemoryRouter,
} from "react-router-dom";
import { StaticRouter } from 'react-router-dom/server';
import './index.css';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import reportWebVitals from './reportWebVitals';
import "@fontsource/karla";

import { UserProvider } from './context/userContext';
import ErrorPage from './pages/error';
import Profile from './pages/profile';
import { coreTheme } from './styles/themes';
import LandingPage from './pages/landingpage';
import MapPage, { mapBasePath, mapEditPath } from './pages/map';
import { MapTokenProvider } from './context/mapToken';
import MyMaps from './pages/mymaps';
import TermsPage from './pages/terms';
import AboutPage from './pages/about';
import FaqPage from './pages/faq';
import PrivacyPage from './pages/privacy';
import UserMaps from './pages/usermaps';

const LinkBehavior = React.forwardRef((props, ref) => {

  // @ts-expect-error TS(2339) FIXME: Property 'href' does not exist on type '{}'.
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)

  // @ts-expect-error TS(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message
  return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
});

LinkBehavior.propTypes = {

  // @ts-expect-error TS(2322) FIXME: Type '{ href: PropTypes.Validator<string | PropTyp... Remove this comment to see the full error message
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

function Router(props: any) {
  const { children } = props;
  if (typeof window === 'undefined') {

    return <StaticRouter location="/">{children}</StaticRouter>;
  }


  return <MemoryRouter>{children}</MemoryRouter>;
}

let Navbar = React.lazy(() => import("./components/navbar"))
 
export async function lazyNavBarLoader() {
  const componentModule = await import("./components/navbar")
  // This avoid flicker from React.lazy by using the component directly
  // @ts-ignore
  Navbar = componentModule.default
  return null
}
 
function LazyNavbar() {
  return (
    <React.Suspense fallback="loading...">
      <Navbar />
    </React.Suspense>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navbar/>,
    loader: lazyNavBarLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/mymaps",
        element: <MyMaps />,
      },
      {
        path: "/user/" + ":userId",
        element: <UserMaps />
      },
      {
        path: mapBasePath + ":mapId" + mapEditPath,
        element: <MapPage editting={true} />,
      },
      {
        path: mapBasePath + ":mapId",
        element: <MapPage editting={false} />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "terms",
        element: <TermsPage />
      },
      {
        path: "privacy",
        element: <PrivacyPage />
      },
      {
        path: "about",
        element: <AboutPage />
      },
      {
        path: "faq",
        element: <FaqPage />
      }
    ],
  },
]);



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // TODO: add back <React.StrictMode> when authUI is fixed.
    <ThemeProvider theme={coreTheme}>
      <UserProvider>
        <MapTokenProvider>
          <CssBaseline />
          <RouterProvider router={router} />
        </MapTokenProvider>
      </UserProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
reportWebVitals();
