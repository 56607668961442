import React, { useContext } from 'react';

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { CustomTabPanel, a11yProps } from "../components/tabPanel"

import MapList from '../components/maplist';
import { getMaps, getMyMaps, removeNull } from '../query/db/map';
import { UserContext } from '../context/userContext';
import { Navigate } from 'react-router';
import ShareUserProfileButton from '../components/shareProfileButton';
import { Stack, Typography } from '@mui/material';
import FollowsProfileList from '../components/followsProfileList';


export default function MyMaps() {
  const [value, setValue] = React.useState(0);
  const userCtx = useContext(UserContext)

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const myMapFetcher = (async () => {
    let userId = userCtx.user?.user.uid
    if (userId) {
      return await getMyMaps(userId)
    }
    return null
  })

  const favoritedMapFetcher = (async () => {
    let bookmarkedMaps = userCtx.user?.bookmarkedMaps
    if (bookmarkedMaps) {
      if (bookmarkedMaps.length === 0) {
        return []
      }
      let maps = await getMaps(bookmarkedMaps)
      return removeNull(maps)
    }
    return null
  })

  if (!userCtx?.loadingUser) {
    if (!userCtx?.user) {
        return ( <Navigate to="/"/> )
    }    
  }

  return (
    <Box id="map" margin={"16px"} >
      <Stack direction="row" flex={1}>
        <Typography variant='h1' display={'flex'} flexGrow={1}>Home</Typography>
        { userCtx.user?.user.uid && <ShareUserProfileButton userId={userCtx.user.user.uid}/>}
      </Stack>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <Tabs value={value} variant='scrollable' scrollButtons='auto' onChange={handleChange} aria-label="map list tabs" >
          <Tab label="My Maps" {...a11yProps(0)} />
          <Tab label="Bookmarked Maps" {...a11yProps(1)} />
          <Tab label="Followed Profiles" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        < MapList mapFetcher={myMapFetcher} searchFilter='' />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        < MapList mapFetcher={favoritedMapFetcher} searchFilter='' />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <FollowsProfileList/>
      </CustomTabPanel>
    </Box>
  );
}