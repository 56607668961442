import React, { useEffect, useState } from "react";

import Modal from '@mui/material/Modal'
import { RecMap, updateMap } from "../query/db/map";
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { modalBoxStyle } from "../styles/themes";
import Typography from '@mui/material/Typography'
import useUserOwnsMap from "../hooks/userOwnsMap";
import { mapBasePath } from "../pages/map";
import IconButton from "@mui/material/IconButton";
import IosShareIcon from '@mui/icons-material/IosShare';
import { copyTextToClipboard } from "../helpers/clipboard";

export default function ShareMapButton({map, onMapUpdate}:{map:RecMap, onMapUpdate:(m:RecMap)=>void}) {

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [mapURL, setMapURL] = useState("https://spotmap.app/map/id/" + map.id)
    const [savingUpdate, setSavingUpdate] = useState(false)
    const [permissionUpdate, setPermissionUpdate] = useState(map.data.permissions)
    const [copied, setCopied] = useState(false)
  
    const showModal = () => { setModalOpen(true) };
    const closeModal = () => {
        setPermissionUpdate(map.data.permissions)
        setModalOpen(false)
    };
  
    const userOwnsMap = useUserOwnsMap(map)

    useEffect(() => {
        let mapURL = "https://spotmap.app/map/id/" + map.id
        if (typeof window !== 'undefined') {
            mapURL = window.location.protocol + '//' + window.location.host + mapBasePath + map.id;
        }
        setMapURL(mapURL)
    }, [map.id])
  
    const shareButton = (
    <IconButton
        onClick={showModal}
        ><IosShareIcon/>
    </IconButton>)

    let savePermissions = () => {
        setSavingUpdate(true)
        updateMap(map, {permissions: permissionUpdate}).then((m) => {
            setSavingUpdate(false)
            onMapUpdate(m)
            setPermissionUpdate(m.data.permissions)
            setModalOpen(false)
        })
    }

    let copyURL = () => {
        copyTextToClipboard(mapURL)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }

    if (!modalOpen) {
      return <>{shareButton}</>
    }
  
    return (
      <>
      {shareButton}
      <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description" 
              open={modalOpen}
              onClose={closeModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
              backdrop: {
                  timeout: 500,
              },
              }}
            >
              <Fade in={modalOpen}>
                  <Box sx={{...modalBoxStyle, p:0 }}>
                  <Box sx={{ p:4 }}>
                  <Typography variant='h5'>Share Map</Typography>
                  { userOwnsMap && 
                      <Stack direction="row">
                        <Box flexDirection={"column"} display={"flex"} justifyContent={"center"} paddingRight={"16px"}>
                            <Typography>Permissions:</Typography>
                        </Box>
                        <Stack direction={"column"} display={"flex"} flex={1}>
                            <Select
                                value={permissionUpdate}
                                variant="standard"
                                fullWidth
                                onChange={(e) => {
                                    if (e.target.value === "public" || e.target.value === "private" || e.target.value === "shared") {
                                        setPermissionUpdate(e.target.value)
                                    }
                                }}
                                labelId="select-permissioned-label"
                                id="select-permissions"
                                >
                                    <MenuItem value={"private"}>Private</MenuItem>
                                    <MenuItem value={"public"}>Public</MenuItem>
                            </Select>
                            {permissionUpdate === "public" && <Typography variant="caption">Anyone with the link can see your map.</Typography>}
                            {permissionUpdate === "private" && <Typography variant="caption">Only you can see your map.</Typography>}
                        </Stack>
                      </Stack>
                    }
                    { (permissionUpdate === "public") && 
                        <Stack direction={"column"}>
                            <Button sx={{marginTop:"8px"}} variant="outlined" onClick={copyURL}>{copied ? "Copied!" : "Copy Link 🔗"}</Button>
                        </Stack>
                    }
                    </Box>
                    { userOwnsMap && 
                    <Stack direction={"row"} display={"flex"}>
                        <Box flex={1} display={"flex"}/>
                        <Button sx={{p:"1", margin:"8px"}} onClick={savePermissions} disabled={savingUpdate || map.data.permissions === permissionUpdate}>Save</Button>
                    </Stack>
                    }
                  </Box>
              </Fade>
            </Modal>
        </>
    )
  }