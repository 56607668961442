import Stack from '@mui/material/Stack'

import { Container, Grid, Typography } from "@mui/material"
import BottomNav from "../components/bottomNav"

export default function FaqPage() {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} paddingTop={"40px"} >
                <Grid item xs={12} display={"flex"}>
                    <Stack justifyContent={"center"}>
                        <Typography variant="h1">FAQ</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} display={"flex"}>
                    <Stack justifyContent={"center"}>
                        <Typography variant="h6">Body</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <BottomNav/>
                </Grid>
            </Grid>
        </Container>
    )
}