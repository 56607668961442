import { useContext, useEffect, useMemo, useState } from "react";
import { generate as randString } from "randomstring"

import TextField from '@mui/material/TextField'
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { debounce } from '@mui/material/utils';
import { MapTokenContext } from "../../context/mapToken";
import { Place, RecMap } from "../../query/db/map";
import { toMapkitRegion, toRegion } from "../../query/search/appleSearch";
import { Region, SearchResult, regionsEqual, regionsNear, regionsSimilarSize } from "../../query/search/common";
import Button from "@mui/material/Button";

export interface AutocompleteOption {
    searchResult: mapkit.SearchAutocompleteResult|null
    exactInput: string|null
}

export interface AutocompleteInputProps {
    map:RecMap,
    viewRegion:Region|null,
    setSearchResults: (places:SearchResult[])=>void,
    setViewRegion: (region:Region)=>void,
}

interface SearchService {
    search: mapkit.Search
    region: Region|null
}

function createSearch(region:Region|null):SearchService {
    let search:mapkit.SearchAutocompleteOptions = {includeAddresses:true, language:navigator.language}
    if (region) {
        search = {region: toMapkitRegion(region), includeAddresses:true}
    }
    return { search: new mapkit.Search(search), region:region}
}

export function AutocompleteInput({map, viewRegion, setSearchResults, setViewRegion}:AutocompleteInputProps) {
    const mapTokenCtx = useContext(MapTokenContext)

    const [searchService, setSearchService] = useState<SearchService|null>(null)
    const [selectedValue, setSelectedValue] = useState<AutocompleteOption | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [viewRegionMoved, setViewRegionMoved] = useState(false)
    const [selectedSearchItem, setSelectedSearchItem] = useState<AutocompleteOption|null>(null)
    const [updateSearchViewRegion, setUpdateSearchViewRegion] = useState(viewRegion)
    const [options, setOptions] = useState<readonly AutocompleteOption[]>([{exactInput:inputValue, searchResult:null}]);

    const fetch = useMemo(
        () => {
          return debounce(
            (
              query:string,
              callback: mapkit.AutocompleteSearchCallback,
            ) => {
                if (searchService) {
                    searchService.search.autocomplete(
                        query,
                        callback,
                    );
                }
            },
            400,
          )},
        [inputValue],
      );

      const issueSearch = (error: Error | null, data: mapkit.SearchAutocompleteResponse) => {
        let newOptions: readonly AutocompleteOption[] = [];

        newOptions = [...newOptions, {exactInput: inputValue, searchResult:null}]
        if (data) {
            newOptions = [
            ...newOptions,
            ...data.results.map(res => {return {exactInput: null, searchResult:res}})];
        }

        setOptions(newOptions);
    }

    useEffect(() => {
        setUpdateSearchViewRegion(viewRegion)
        if (searchService) {
            if (!regionsNear(searchService.region, viewRegion) || !regionsSimilarSize(searchService.region, viewRegion)) {
                setViewRegionMoved(true)
            }
        }
    }, [viewRegion, searchService?.region])

    useEffect(() => {
        let active = true;
        if (!mapTokenCtx.mapkitLoaded || mapTokenCtx.mapkitLoadFailed) {
            return undefined
        }

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        if (mapTokenCtx.mapkitLoaded && (
            (!searchService && inputValue) 
                || (searchService && !regionsEqual(viewRegion, searchService.region)))
        ) {
            setSearchService(createSearch(viewRegion))
        }

        fetch(inputValue, (e, data) => {
            if (active) {
                issueSearch(e, data)
            }
        });

        return () => {
            active = false;
        };
    }, [selectedValue, searchService, inputValue, mapTokenCtx.mapkitLoaded]);

    let handleSearchResult = (
        error:Error|null,
        data:{displayRegion?: mapkit.CoordinateRegion | undefined, places: mapkit.Place[]}
      ) => {
        if (data.displayRegion) {
            const r = toRegion(data.displayRegion.toBoundingRegion())
            if (searchService) {
                searchService.region = r
            }
            setViewRegion(r)
            setUpdateSearchViewRegion(r)
            setViewRegionMoved(false)
        }
        let searchedPlaces = data.places.filter((m => {
            // Dont add places that are already in our map
            for (let i = 0; i < (map.places||[]).length; i++) {
                const p = (map.places||[])[i];
                if (p.place.address === m.formattedAddress
                    && p.place.name === m.name
                    && Math.abs(p.place.lat - m.coordinate.latitude) < 0.00001
                    && Math.abs(p.place.lng - m.coordinate.longitude) < 0.00001) {
                        return false
                    }
            }
            return true
        })).map(m => {
            let tags = m.pointOfInterestCategory ? [m.pointOfInterestCategory.toString()] : []
            let place:Place = {
                name: m.name,
                address: m.formattedAddress,
                tags: tags,
                lat: m.coordinate.latitude,
                lng: m.coordinate.longitude,
                pinImg: "",
                imageSrcs: [],
                notes: "",
                pinTags: [],
            }
            return { place: place, itemId: randString(16) }
        })
        setSearchResults(searchedPlaces)
    }

    let onSelectItem = (search:SearchService|null, item:AutocompleteOption) => {
        setSelectedSearchItem(item)
        if (search) {
            if (item.exactInput) {
                search.search.search(item.exactInput, (error, data) => {
                    // Hack to munge the display region to bounding region since it seems like mapkit js has wrong type info
                    let d = (data as {boundingRegion?:mapkit.CoordinateRegion, places:mapkit.Place[]})
                    data.displayRegion = data.displayRegion ? data.displayRegion : d.boundingRegion
                    handleSearchResult(error, data)
                })
            }
            if (item.searchResult) {
                search.search.search(item.searchResult, (error, data) => {
                    // Hack to munge the display region to bounding region since it seems like mapkit js has wrong type info
                    let d = (data as {boundingRegion?:mapkit.CoordinateRegion, places:mapkit.Place[]})
                    data.displayRegion = data.displayRegion ? data.displayRegion : d.boundingRegion
                    handleSearchResult(error, data)
                })
            }
        }
    }

    let redoSearch = () => {
        if (selectedSearchItem) {
            let newSearch = createSearch(updateSearchViewRegion)
            setSearchService(newSearch)
            onSelectItem(newSearch, selectedSearchItem)
            setViewRegionMoved(false)
        }
    }

    return (
        <>
        <Autocomplete
        autoComplete
        freeSolo
        id="place-search"
        filterSelectedOptions
        blurOnSelect="touch"
        filterOptions={(x) => x}
        clearOnBlur={false}
        value={selectedValue}
        fullWidth={true}
        sx={{
          backgroundColor: "common.white",
          boxShadow: "1",
          borderRadius:"12px",
        }}
        getOptionLabel={(option:string|AutocompleteOption) => {
            if (typeof option === "string") {
                return option
            }
            if (option.exactInput) {
                return option.exactInput
            }
            if (option.searchResult) {
                return option.searchResult.displayLines[0]
            }
            return ""
        }}
        onKeyDown={(event) => {
            if (event.key === "Enter") {
                document.getElementById("place-search")?.blur()
            }
        }}
        onChange={(event: any, newValue: string|AutocompleteOption|null) => {
            if (typeof newValue === "string") {
                newValue = {exactInput: newValue, searchResult: null}
            }
            if (newValue) {
                onSelectItem(searchService, newValue)
            }
            setOptions(newValue ? [newValue, ...options] : options);
            setSelectedValue(newValue);
          }}
        onInputChange={(event, newInputValue) => {
            if (newInputValue == '') {
                setSearchResults([])
            }
            setInputValue(newInputValue);
        }}
        renderOption={(props, option) => {
            if (typeof option === "string") {
                return <ListItem {...props} key={"stropt:" + option}><Typography>{option}</Typography></ListItem>
            }
            let optionItem = <Typography/>
            let itemKey = ""
            if (option.exactInput) {
                itemKey = "extopt:" + option.exactInput
                optionItem = <><SearchIcon fontSize="small"/><Typography>{option.exactInput}</Typography></>
            }
            if (option.searchResult) {
                let optionName = option.searchResult?.displayLines[0]
                let address = option.searchResult?.displayLines[1]
                let searchIcon = <></>
                if (address === 'Search Nearby') {
                    searchIcon = <SearchIcon fontSize="small"/>
                }
                itemKey = "seropt:" + optionName + "|" + address
                optionItem = (<>{searchIcon}<ListItemText primary={optionName} secondary={address}/></>)
            }
            return <ListItem {...props} key={itemKey}>{optionItem}</ListItem>
        }}
        options={options}
        renderInput={(params) => {
            return (<TextField
                {...params}
                placeholder="Search for coffee shops, hotels, etc"
                InputProps={{
                ...params.InputProps,
                startAdornment: (<InputAdornment position="start" sx={{marginRight:"4px"}}><SearchIcon sx={{paddingLeft:"2px"}}/></InputAdornment>),
                sx: { borderRadius: '12px', "& fieldset": { border: 'none' },}
                }}
                 />)
        }}/>
        { viewRegionMoved && (selectedSearchItem !== null) && <Button variant="contained" onClick={redoSearch} sx={{margin:"auto", marginTop:"12px", display:"block"}}>Search this area</Button> }
        </>
    )
}