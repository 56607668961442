import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Picker from '@emoji-mart/react';
import { FrequentlyUsed, Store } from 'emoji-mart'

const initialFrequentEmoji = ["coffee", "bubble_tea", "hamburger", "pancakes", "knife_fork_plate", "bagel", "doughnut", "croissant", "heart_eyes"]
const categories = ["frequent", "foods", "places", "nature", "activity", "flags", "objects", "symbols", "people"]

function initFrequentlyUsed() {
    if (!Store.get('frequently')) {
        for (let i = 0; i < initialFrequentEmoji.length; i++) {
            const emojiID = initialFrequentEmoji[i];
            FrequentlyUsed.add({id: emojiID})
        }
    }
}

export default function EmojiPickerPopover({pinImgElem, onSelect}:{pinImgElem:JSX.Element, onSelect:(v:string, tags:string[])=>void}) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement|null>(null);

    useEffect(() => {
        initFrequentlyUsed()
    }, [])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'emoji-picker-popover' : undefined;

    let selectEmoji = (emoji: any, event: MouseEvent) => {
        onSelect(emoji.native, emoji.keywords)
        handleClose()
    }

    let clearEmoji = () => {
        onSelect("", [])
        handleClose()
    }

    return (
        <>
        <Button 
        aria-describedby={id} onClick={handleClick}
        variant="outlined"
        sx={{ height:"40px", minWidth:"40px", maxWidth:"40px", justifyContent:"center", textAlign:"center"}}
        >{pinImgElem}
        </Button>
        <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            > 
                <Button fullWidth={true} variant='outlined' onClick={clearEmoji}>Clear Selection</Button>
                <Picker data={null} maxFrequentRows={2} categories={categories} set="native" onEmojiSelect={selectEmoji} />
        </Popover>
        </>
    )
}