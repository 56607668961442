import React, { useContext, useEffect, useState } from 'react';

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { CustomTabPanel, a11yProps } from "../components/tabPanel"

import MapList from '../components/maplist';
import { getUserPublicMaps, removeNull } from '../query/db/map';
import { UserContext } from '../context/userContext';
import { useNavigate, useParams } from 'react-router';
import { followUser, getUserDisplayInfo, unfollowUser } from '../query/db/user';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import ShareUserProfileButton from '../components/shareProfileButton';

export const userPageBase = '/user/'

export default function UserMaps() {
  const [value, setValue] = React.useState(0);
  const { userId } = useParams<string>()
  const userCtx = useContext(UserContext)
  const [userName, setUserName] = useState("");
  const [followed, setFollowed] = useState<boolean|undefined>(undefined);

  if (!userId) {
    throw new Error("userId not supplied")
  }

  const navigate = useNavigate()
  if (userId === userCtx.user?.user.uid) {
    navigate("/mymaps")
  }

  useEffect(() => {
    getUserDisplayInfo(userId).then((info) => {
      setUserName(info.displayName)
    })
  }, [userId])

  useEffect(() => {
    if (userCtx.user) {
      setFollowed((userCtx.user?.follows || []).includes(userId || ""))
    }
    if (!userCtx?.loadingUser) {
      if (!userCtx?.user) {
        setFollowed(undefined)
      }
    }
  }, [userCtx, userCtx.user, userCtx.user?.follows, userId])

  const handleTabSwitch = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const userMapFetcher = (async () => {
      let maps = await getUserPublicMaps(userId)
      return removeNull(maps)
  })

  const followFn = () => {
    if (userCtx.user && userId) {
      followUser(userCtx.user, userId).then(() => {
        setFollowed((userCtx.user?.follows || []).includes(userId || ""))
      })
    }
  }

  const unfollowFn = () => {
    if (userCtx.user && userId) {
      unfollowUser(userCtx.user, userId).then(() => {
        setFollowed((userCtx.user?.follows || []).includes(userId || ""))
      })
    }
  }

  let followButton = <></>
  
  if (followed !== undefined) {
    followButton = followed ?
    <Button variant='outlined' sx={{margin: "12px", marginLeft:"0px", whiteSpace: "nowrap", minWidth: "auto"}} onClick={unfollowFn}>Unfollow</Button>
     : <Button variant='contained' sx={{margin: "12px", marginLeft:"0px", whiteSpace: "nowrap", minWidth: "auto"}} onClick={followFn}>Follow</Button>
  }

  return (
    <Box id="map" margin={"16px"} >
      <Stack direction="row" flex={1}>
            <Box display={'flex'} flexGrow={1}>{userName !== "" && <Typography variant="h1" alignSelf={'center'}>{userName}</Typography>}</Box>
            { (userCtx.loadingUser) ? <CircularProgress/> : followButton}
            { userId && <ShareUserProfileButton userId={userId}/>}
      </Stack>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleTabSwitch} aria-label="map list tabs" >
          <Tab label="Maps" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        < MapList mapFetcher={userMapFetcher} searchFilter=''/>
      </CustomTabPanel>
    </Box>
  );
}