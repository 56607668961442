import { DocumentData, Timestamp, arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { firestore, firestoreFns } from "../../config/firebase-config"
import { createEmptyMap, saveNewMap } from "./map"
import { User } from "firebase/auth"
import { httpsCallable } from "firebase/functions"
import { generateGradient } from "../../utils/gradient"

export interface UserData {
    user: User,
    bookmarkedMaps: string[],
    favoritesMap: string,
    viewedMaps: string[],
    follows: string[],
    createdAt: number,

    profileGradient?: string
}

export const userCollection = "users"

export const defaultUserGradient = 'linear-gradient(273deg, #80ffdb 37%, #72efdd 52%)'

const toFirestore = (userData: UserData):DocumentData => {
    return {
        bookmarkedMaps: userData.bookmarkedMaps,
        favoritesMap: userData.favoritesMap,
        viewedMaps: userData.viewedMaps,
        follows: userData.follows,
        createdAt: Timestamp.fromMillis(userData.createdAt),

        profileGradient: userData.profileGradient,
    };
}

const fromFirestore = (userDoc: DocumentData, user:User):UserData => {
    return {
        user: user,
        bookmarkedMaps: userDoc.bookmarkedMaps ? userDoc.bookmarkedMaps : [],
        favoritesMap: userDoc.favoritesMap,
        viewedMaps: userDoc.viewedMaps,
        follows: userDoc.follows,
        createdAt: (userDoc.createdAt as Timestamp).toMillis(),

        profileGradient: userDoc.profileGradient,
    };
}

async function saveUserData(userData:UserData):Promise<UserData> {
    if (!userData?.user?.uid) {
        throw new Error("no user id")
    }
    await setDoc(doc(firestore, userCollection, userData.user.uid),
        toFirestore(userData), {merge: true},
    )
    return userData
}

async function initUser(user:User):Promise<UserData> {
    let favoritesMap = await saveNewMap(createEmptyMap(user.uid, "Favorites"))
    let userData:UserData = {
        user: user,
        bookmarkedMaps: [],
        favoritesMap: favoritesMap.id,
        follows: [],
        viewedMaps: [],
        createdAt: Date.now(),
        profileGradient: generateGradient()
    }
    await saveUserData(userData)
    return userData
}

export async function favoriteMap(user:UserData, mapId:string):Promise<void> {
    let docRef = doc(firestore, userCollection, user.user.uid)
    await updateDoc(docRef, { "bookmarkedMaps": arrayUnion(mapId) }).then(() =>
        user.bookmarkedMaps = [...user.bookmarkedMaps, mapId]
    )
}

export async function unfavoriteMap(user:UserData, mapId:string):Promise<void> {
    let docRef = doc(firestore, userCollection, user.user.uid)
    await updateDoc(docRef, { "bookmarkedMaps": arrayRemove(mapId) }).then(() =>
        user.bookmarkedMaps = [...user.bookmarkedMaps.filter(mid => mid !== mapId)]
    )
}

export async function followUser(user:UserData, userId:string):Promise<void> {
    let docRef = doc(firestore, userCollection, user.user.uid)
    await updateDoc(docRef, { "follows": arrayUnion(userId) }).then(() =>
        user.follows = [...(user.follows || []), userId]
    )
}

export async function unfollowUser(user:UserData, userId:string):Promise<void> {
    let docRef = doc(firestore, userCollection, user.user.uid)
    await updateDoc(docRef, { "follows": arrayRemove(userId) }).then(() =>
        user.follows = [...(user.follows || []).filter(uid => uid !== userId)]
    )
}

export async function setProfileGradient(user:UserData, profileGradient:string) {
    let docRef = doc(firestore, userCollection, user.user.uid)
    await updateDoc(docRef, { profileGradient: profileGradient }).then(() =>
        user.profileGradient = profileGradient
    )
}

export async function getUserData(user:User):Promise<UserData> {
    const userRef = doc(firestore, userCollection, user.uid)
    const userDoc = await getDoc(userRef)
    if (!userDoc.exists()) {
        return initUser(user)
    }

    return { ...fromFirestore(userDoc.data(), user), user: user} as UserData
}

const getUserDisplayInfoFn = httpsCallable(firestoreFns, 'getUserDisplayInfo')
export async function getUserDisplayInfo(userId:string) {
    let res = await getUserDisplayInfoFn({userId: userId})
    const info = (res.data  as {displayName:string, profileGradient:string|undefined})
    if (!info.profileGradient) {
        info.profileGradient = defaultUserGradient
    }
    return info
}