import React, { useContext, useEffect, useState } from "react";

import Modal from "@mui/material/Modal";

import { RecMap, RecMapDataUpdate, updateMap } from "../query/db/map";
import { generateGradient } from "../utils/gradient";
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Backdrop from '@mui/material/Backdrop';
import { modalBoxStyle } from "../styles/themes";
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { UserContext } from "../context/userContext";
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteMapButton from "./mapDelete";

export default function EditMapDetailsButton({map, onMapUpdate}:{map:RecMap, onMapUpdate:(map:RecMap)=>void}) {

    let userCtx = useContext(UserContext)

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [isFavoritesMap, setIsFavoritesMap] = useState<boolean>(false)
    const [updateBgGradient, setUpdateBgGradient] = useState(map.data.backgroundGradient)
    let [updateMapName, setUpdateMapName] = useState(map.data.mapName)
    let [savingMapDetails, setSavingMapDetails] = useState(false)

    const showModal = () => { setModalOpen(true) };
    const closeModal = () => { 
        setModalOpen(false)
     };

    useEffect(() => {
        setUpdateMapName(map.data.mapName)
    }, [map.data.mapName, map.data, map])

    useEffect(() => {
        if (userCtx.user?.favoritesMap === map.id) {
          setIsFavoritesMap(true)
        }
      }, [userCtx.user, map])

    let save = () => {
        setSavingMapDetails(true)
        let update:RecMapDataUpdate = {}
        if (updateMapName !== map.data.mapName) {
            update = {...update, mapName: updateMapName}
        }
        if (updateBgGradient !== map.data.backgroundGradient) {
            update = {...update, backgroundGradient:updateBgGradient}
        }
        updateMap(map, update).then((m) => {
            onMapUpdate(m)
            closeModal()
        }).finally(() => {
            setSavingMapDetails(false)
        })
    }

    let noUpdates = () => {
        return updateMapName === map.data.mapName
            && updateBgGradient === map.data.backgroundGradient
    }

    let updateGradient = () => {
        setUpdateBgGradient(generateGradient())
    }

    let editMapDetailsButton = (<IconButton onClick={showModal}><SettingsIcon/></IconButton>)

    if (!modalOpen) {
        return (<>{editMapDetailsButton}</>)
    }
  
    return (
      <>
      {editMapDetailsButton}
      <Modal
              aria-labelledby="edit-map-details"
              aria-describedby="edit-map-details-modal" 
              open={modalOpen}
              onClose={closeModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
              backdrop: {
                  timeout: 500,
              },
              }}
            >
              <Fade in={modalOpen}>
                  <Box sx={modalBoxStyle}>
                  <Typography  variant="h5">Settings</Typography>
                    <Typography>Map Name</Typography>
                    <Stack direction={"row"}>
                        <TextField value={updateMapName} sx={{padding:"0px"}} disabled={isFavoritesMap} onChange={(e) => setUpdateMapName(e.target.value)}/>
                    </Stack>
                    <Typography>Thumbnail</Typography>
                    <Stack direction={"row"}>
                        <Box sx={{background: updateBgGradient, height:"40px", width: "80px", marginRight: "8px", borderRadius:"12px"}}/>
                        <Button onClick={updateGradient}> Generate New</Button>
                    </Stack>  
                    <Stack direction={"row"} marginTop={"32px"}>
                    { !isFavoritesMap && <DeleteMapButton map={map}/>}<Box flex={1}/><Button disabled={savingMapDetails || noUpdates()} onClick={save}>Save</Button>
                    </Stack>
                  </Box>
              </Fade>
            </Modal>
        </>
    )
  }