import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/userContext';
import { RecMap } from '../query/db/map';

export default function useUserOwnsMap(map:RecMap|null) {
    const userCtx = useContext(UserContext)
    const [userOwnsMap, setUserOwnsMap] = useState<boolean|null>(null)

    useEffect(() => {
        if (!userCtx.loadingUser && map !== null) {
            console.log(`loadingUser: ${userCtx.loadingUser} mapOwner: ${map?.data.owner} currentUser: ${userCtx?.user?.user.uid} `)
            if (map && userCtx.user && map.data.owner === userCtx.user.user.uid) {
                setUserOwnsMap(true)
            } else {
                setUserOwnsMap(false)
            }
        }
      }, [map, userCtx.loadingUser, userCtx.user, userCtx.user?.user])

    return userOwnsMap
}