import { CSSProperties, ChangeEventHandler, MouseEventHandler, forwardRef, useContext, useEffect, useRef, useState } from "react";

import { RecMap, addPlace, deletePlace, updatePlace, SavedPlace, PlaceUpdate, Place, copyToFavorites } from "../query/db/map";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import PlaceIcon from '@mui/icons-material/Place'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import { coreTheme, savedPlaceColor } from "../styles/themes";
import useWidth from "../hooks/windowWidth";
import Typography from '@mui/material/Typography'
import { SearchResult } from "../query/search/common";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { mapOverlayZIndex } from "../pages/map";
import EmojiPickerPopover from "./emojiPicker";
import { ImageList, ImageListItem, Skeleton, SxProps } from "@mui/material";
import { deleteImage, getImageURL, uploadImage } from "../query/storage/images";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import useUserOwnsMap from "../hooks/userOwnsMap";
import { UserContext } from "../context/userContext";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Lightbox } from "react-modal-image";
import HeartPlusIcon from "./heartPlusIcon";

export const placeCardMaxWidth = 450
const notesCollapsedSize = 50
const imageDeleteButtonStyle:SxProps = {position:"absolute", right:"8px", top:"8px", padding:"0px", opacity:"30%", backgroundColor: "white", color:coreTheme.palette.common.black}
const imageStyle:CSSProperties = {borderRadius: "28px", border: "1px solid", borderColor:"#f0f0f0", height:"100%", width:"100%", objectFit:"cover"}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function openDirections(lat:number, lng:number) {
    // If it's an iPhone..
    if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) {
      function iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
          // supports iOS 2.0 and later
          var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
          if (!v) {
            return null
          }
          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || "0", 10)];
        }
      }
      var ver = iOSversion() || [0];

      var protocol = 'http://';
      if (ver[0] >= 6) {
        protocol = 'maps://';
      }
      window.open(protocol + 'maps.apple.com/maps?daddr=' + lat + ',' + lng + '&amp;ll=', "_blank");
    }
    else {
      window.open('http://maps.google.com?daddr=' + lat + ',' + lng + '&amp;ll=', "_blank");
    }
}

const checkChanges = (place:SearchResult|SavedPlace, notesUpdate:string, pinImgUpdate:string, newImages:File[], deleteImages:string[]) => {
    return notesUpdate === place.place.notes 
            && pinImgUpdate === place.place.pinImg 
            && newImages.length === 0
            && deleteImages.length === 0
}

export default function PlaceCardOverlay({recMap, place, inEdittingMode, closeFn, onAddPlace}:{
    recMap:RecMap,
    place:SearchResult|SavedPlace, 
    inEdittingMode:boolean, 
    closeFn:()=>void, 
    onAddPlace:(displayId:string, savedPlaceId:string)=>void}
) {
    const width = useWidth()
    const userOwnsMap = useUserOwnsMap(recMap)
    const userCtx = useContext(UserContext)

    const [savingPlace, setSavingPlace] = useState(false)
    const [collapsedNoteHeight, setCollapsedNoteHeight] = useState(notesCollapsedSize)
    const [cardSize, setCardSize] = useState<{height:string, top:string, narrow:boolean}>({height: "35%", top:"62%", narrow:true})
    const [notesUpdate, setNotesUpdate] = useState(place.place.notes)
    const [pinImgUpdate, setPinImgUpdate] = useState(place.place.pinImg)
    const [pinTags, setPinTags] = useState<string[]>([])
    const [notesCollapsed, setNotesCollapsed] = useState(true)
    const [newImages, setNewImages] = useState<File[]>([])
    const [newImageURLs, setNewImageURLs] = useState<string[]>([])
    const [deleteImages, setDeleteImages] = useState<string[]>([])
    const [savedImageURLs, setSavedImageURLs] = useState<{url:string, imageId:string}[]>([])

    const [noChanges, setNoChanges] = useState(checkChanges(place, notesUpdate, pinImgUpdate, newImages, deleteImages))
    const [loadingImages, setLoadingImages] = useState(place.place.imageSrcs.length > 0)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [copyingToFavs, setCopyingToFavs] = useState(false)
    const [imageOpen, setImageOpen] = useState(false)
    const [viewImageURL, setViewImageURL] = useState("")

    let notesRef = useRef<HTMLDivElement>(null)
    let searchItemId = (place as SearchResult).itemId
    let isSearchCard = Boolean(searchItemId)
    let savedPlaceId = (place as SavedPlace).id
    let hiddenImageUpload = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setNotesUpdate(place.place.notes)
        setPinImgUpdate(place.place.pinImg)
    }, [place, place.place, place.place.notes, place.place.pinImg])

    useEffect(() => {
        setNoChanges(checkChanges(place, notesUpdate, pinImgUpdate, newImages, deleteImages))
    }, [place, notesUpdate, pinImgUpdate, newImages, deleteImages]) // newImages check for changes happens in dedicated hook

    useEffect(() => {
        const imageURLs:string[] = []
        newImages.forEach(i => imageURLs.push(URL.createObjectURL(i)))
        setNewImageURLs(imageURLs)
    }, [newImages])

    useEffect(() => {
        setLoadingImages(place.place.imageSrcs.length > 0)
        const getURLPromises:Promise<{imageId:string, url:string}>[] = []
        place.place.imageSrcs.forEach((i) => {
            let p = getImageURL(recMap.id, savedPlaceId, i).then(url => {
                return {imageId: i, url:url}
            })
            getURLPromises.push(p)
        })
        Promise.all(getURLPromises).then(l => {
            setSavedImageURLs(l)
        }).finally(() => {
            setLoadingImages(false)
        })
    }, [place, place.place, place.place.imageSrcs, recMap.id, savedPlaceId])

    useEffect(() => {
        let narrow = true
        let height = "94%"
        let top = "15%"
        if (width && width > coreTheme.breakpoints.values.sm) {
            height = "94%"
            top = "15%"
            narrow = false
        }
        setCardSize({height: height, top:top, narrow:narrow})
        setNotesCollapsed(narrow)
    }, [width])

    useEffect(() => {
        if (notesRef.current) {
            if (notesCollapsedSize > notesRef.current.clientHeight) {
                setCollapsedNoteHeight(notesRef.current.clientHeight)
            }
        }
    }, [notesRef.current?.scrollHeight, inEdittingMode, notesCollapsed])

    let addThisPlace = () => {    
      setSavingPlace(true)
      place.place.notes = notesUpdate
      place.place.pinImg = pinImgUpdate
      place.place.pinTags = pinTags
      addPlace(recMap, place.place).then(async (p) => {
        let uploadPromises:Promise<string>[] = []
        newImages.forEach(i => {
            uploadPromises.push(uploadImage(recMap.id, p, i))
        })
        await Promise.all(uploadPromises)
        return p
      }).then((p) => {
        onAddPlace(searchItemId, p.id)
      }).finally(() => {
        setSavingPlace(false)
        closeFn()
      })
    }
    let deleteThisPlace = () => {
      if (savedPlaceId) {
        setSavingPlace(true)
        deletePlace(recMap, savedPlaceId).then(() => {
          closeFn()
        }).finally(() => {
            setSavingPlace(false)
        })
      }
    }
    let savePlace = () => {
      let savedPlace = place as SavedPlace
      if (savedPlace.id) { // this functions as a type check 
        setSavingPlace(true)
        let placeUpdate:PlaceUpdate = {
            notes: notesUpdate,
            pinImg: pinImgUpdate,
            pinTags: pinTags,
        }
        let updatePromises:Promise<string|void>[] = []
        newImages.forEach(i => {
            updatePromises.push(uploadImage(recMap.id, savedPlace, i))
        })
        deleteImages.forEach(i => {
            updatePromises.push(deleteImage(recMap.id, savedPlace, i))
        })
        
        Promise.all(updatePromises).then(async () => {
            return await updatePlace(recMap, savedPlace, placeUpdate)
        }).finally(() => {
            setSavingPlace(false)
            // clear internal state lists
            setNewImages([])
            setDeleteImages([])
            closeFn()
        })
      }
    }
    let onImageChange:ChangeEventHandler<HTMLInputElement> = (e) => {
        setNewImages([...(e.target.files||[])])
    }
    let removeNewImage = (indx:number) => {
        let remainingImages = [...newImages.slice(0, indx), ...newImages.slice(indx+1, newImages.length)]
        setNewImages(remainingImages)
    }

    let deleteSavedImage = (indx:number) => {
        setDeleteImages([...deleteImages, place.place.imageSrcs[indx]])
    }

    let showEditFields = inEdittingMode || isSearchCard
    
    let footerHeight = "0px"
    if (showEditFields) {
        footerHeight = "64px"
    }

    let pinImg = showEditFields ? pinImgUpdate : place.place.pinImg
    let placeIcon = <Typography sx={{alignSelf:"center"}}>{pinImg}</Typography>
    if (pinImg === "") {
        placeIcon = <PlaceIcon sx={{alignSelf:"center", color:savedPlaceColor}}/>
    }
    let selectPinImg = (v:string, tags:string[]) => {
        setPinImgUpdate(v)
        setPinTags(tags)
    }
    const handleUploadImages:MouseEventHandler = (event) => {
        if (hiddenImageUpload.current) {
            hiddenImageUpload.current.click();
        }
      };
    let collapseIndex = 50
    let showCollapseButton = place.place.notes.length > collapseIndex
    let lines = place.place.notes.split('\n')
    if (!showCollapseButton && lines.length > 2) {
        showCollapseButton = true
        collapseIndex = lines[0].length + lines[1].length + 1
    }
    let copyToFavoritesMap = (p:Place) => {
        if (userCtx.user?.favoritesMap) {
            setCopyingToFavs(true)
            copyToFavorites(userCtx.user, p).then(() => {
                setOpenSnackbar(true)
            }).finally(() => setCopyingToFavs(false))
        }
    }

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };

    const openLightbox = (url:string) => {
        if (!imageOpen) {
            setViewImageURL(url)
            setImageOpen(true)
        }
    }

    const closeLightbox = () => {
        setImageOpen(false)
        setViewImageURL("")
    }

    return (
        <>
        { imageOpen &&
            <Lightbox
            medium={viewImageURL}
            large={viewImageURL}
            hideDownload={true}
            hideZoom={true}
            
            // @ts-ignore - definitely type package is slightly wrong -- see https://www.npmjs.com/package/react-modal-image
            onClose={closeLightbox}
            />
        }
        <Card sx={{
          maxHeight: cardSize.height,
          height: (cardSize.narrow ? "auto" : cardSize.height),
          zIndex:mapOverlayZIndex,
          position:"absolute",
          bottom:"3%",
          right:"3%",
          left:"3%",
          maxWidth: placeCardMaxWidth+"px",
          minHeight: (showEditFields) ? "200px" : "120px",
          boxShadow: "4",
          display:"flex",
          flexDirection:"column"
          }}>
          <Stack direction={"row"} sx={{textAlign:"start", alignItems:"start", padding:"16px"}}>
            <Box display={"flex"} flex={1} flexDirection={"row"}>
                <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} flex={1} flexDirection={"row"}>
                    {<>
                        <Stack flex={1} display="flex" alignSelf={"center"} >
                            <Typography variant="h6">{place.place.name}</Typography>
                            <Typography flex={1} variant="subtitle2" color={"text.secondary"}>{place.place.tags.join(',')}</Typography>
                        </Stack>
                        {(userCtx.user && !userOwnsMap) &&
                        <IconButton disabled={copyingToFavs} sx={{height: "fit-content"}} onClick={() => copyToFavoritesMap(place.place)}><HeartPlusIcon/></IconButton>}
                        <IconButton onClick={closeFn} sx={{height: "fit-content"}}><CloseIcon/></IconButton>
                    </>}
                    </Stack>
                    <Stack direction={"row"} marginTop={inEdittingMode ? "8px" : "0px"}>
                        {showEditFields ? <EmojiPickerPopover pinImgElem={placeIcon} onSelect={selectPinImg}/> : placeIcon }
                        <Typography flex={1} marginLeft={"8px"} marginTop={"auto"} marginBottom={"auto"} variant="subtitle2" color={"text.secondary"}>{place.place.address}</Typography>
                        { (!inEdittingMode && !isSearchCard) && <IconButton sx={{height: "fit-content"}} onClick={() => openDirections(place.place.lat, place.place.lng)}><DirectionsCarIcon fontSize="small" /></IconButton> }
                    </Stack>
                </Stack>
            </Box>
          </Stack>
          <Box flexDirection={"column"} display={"flex"} flex={1} overflow={"auto"} sx={{paddingLeft:"16px", paddingRight:"16px", paddingTop:"0px", paddingBottom:"24px"}}>
            <Box>
            { showEditFields &&
                <TextField
                multiline 
                fullWidth={true} 
                value={notesUpdate} 
                onChange={e => setNotesUpdate(e.target.value)}
                minRows={2}
                placeholder="And some notes about what you like about this place"
                inputProps={{
                    style:{fontSize:coreTheme.typography.body1.fontSize, font:coreTheme.typography.body1.font}
                }}
                InputProps={{
                    endAdornment: <><IconButton onClick={handleUploadImages}><AddAPhotoIcon fontSize="small"/></IconButton><input ref={hiddenImageUpload} hidden={true} type="file" multiple accept="image/*" onChange={onImageChange}/></>,
                    sx: {alignItems: "flex-end"}
                }}
                sx={{flex:1}}/>
            }
            { place.place.notes && !inEdittingMode && <>
            <Collapse in={!notesCollapsed} collapsedSize={collapsedNoteHeight} sx={{paddingBottom: "8px"}}>
                <Typography ref={notesRef} variant="body1" color="text.main" display={"block"} whiteSpace={"break-spaces"} sx={{wordWrap:"break-word"}}>
                    { notesCollapsed ? place.place.notes.substring(0, collapseIndex) : place.place.notes}
                    { showCollapseButton && !inEdittingMode &&
                    <Button onClick={() => setNotesCollapsed(!notesCollapsed)} sx={{padding: "0px", paddingLeft:"8px", margin:"0px", minWidth: "0px", minHeight:"0px", display: 'inline-block'}}>
                        {notesCollapsed ? "...more" : "less"}
                    </Button>
                    }
                </Typography>
            </Collapse>
            </>}
            { (newImageURLs.length > 0 || savedImageURLs.length > 0) &&
            <ImageList
                component={Stack}
                gap={8}
                direction={"row"}
                sx={{
                    paddingTop: inEdittingMode ? '16px' : '8px',
                    paddingBottom: '4px',
                    gridAutoFlow: "column",
                    height: "151px",
                    width: "auto", 
                    overflow: "auto",
                    gridTemplateColumns: "repeat(auto-fill,minmax(125px,1fr)) !important",
                    gridAutoColumns: "minmax(125px, 1fr)"
                }}
            >
                { loadingImages &&
                    [1, 2, 3].map((i: number) => <ImageListItem key={i} ><Skeleton height="125px" width="125px" sx= {{borderRadius: "28px", borderWidth: "1px", scale: "none", transform:"none"}} /></ImageListItem>)
                }
                {newImageURLs.map((imageURL, indx) => (
                <ImageListItem key={imageURL}>
                    <Box position={"relative"} sx={{borderRadius: "28px", borderWidth: "1px", height:"125px", width:"125px"}}>
                    {inEdittingMode && <IconButton sx={imageDeleteButtonStyle} onClick={() => removeNewImage(indx)} ><CloseIcon fontSize="small"/></IconButton>}
                    <img src={imageURL} alt="" style={imageStyle} onClick={() => openLightbox(imageURL)}/>
                    </Box>
                </ImageListItem>
                ))}
                { !loadingImages && !isSearchCard && savedImageURLs.map((image, indx) => {
                   if (!deleteImages.includes(image.imageId)) {
                    return (
                    <ImageListItem key={image.imageId}>
                        <Box position={"relative"} sx={{borderRadius: "28px", borderWidth: "1px", height:"125px", width:"125px"}}>
                            {inEdittingMode && <IconButton sx={imageDeleteButtonStyle} onClick={() => deleteSavedImage(indx)} ><CloseIcon/></IconButton>}
                            <img src={image.url} alt="" style={imageStyle} onClick={() => openLightbox(image.url)}/>
                        </Box>
                    </ImageListItem>)
                    }
                    return <></>
                  }
                ).reverse()}
            </ImageList>
            }
          </Box>
        </Box>
        { showEditFields && (
        <Box sx={{flex: 0, padding:"8px", height:{footerHeight}}}>
            <Stack direction={"row"}>
            { isSearchCard && 
                <>
                <Box display={"flex"} flex={1} maxWidth="lg" />
                <Button onClick={addThisPlace} disabled={savingPlace} variant="contained">+ Add</Button>
                </>
            }
            { !isSearchCard &&
                <>
                <Button onClick={deleteThisPlace} disabled={savingPlace} color="warning">Delete Place</Button>
                <Box display={"flex"} flex={1} maxWidth="lg" />
                <Button onClick={savePlace} disabled={savingPlace||noChanges}>Save</Button>
                </>
            }
            </Stack>
        </Box>)
        }
        </Card>
        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar} anchorOrigin={{vertical:"bottom", horizontal:"center"}}>
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Added to Favorites Map!
            </Alert>
        </Snackbar>
        </>
    )
  }