import { useEffect, useState } from "react";

import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack'
import { modalBoxStyle } from "../styles/themes";
import Typography from '@mui/material/Typography'
import IconButton from "@mui/material/IconButton";
import IosShareIcon from '@mui/icons-material/IosShare';
import { copyTextToClipboard } from "../helpers/clipboard";

export default function ShareUserProfileButton({userId}:{userId:string}) {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [userProfileURL, setUserProfileURL] = useState("https://spotmap.app/user/" + userId)
    const [copied, setCopied] = useState(false)
  
    const showModal = () => { setModalOpen(true) };
    const closeModal = () => {
        setModalOpen(false)
    };
  
    useEffect(() => {
        let userProfileURL = "https://spotmap.app/user/" + userId
        if (typeof window !== 'undefined') {
            userProfileURL = window.location.protocol + '//' + window.location.host + '/user/' + userId;
        }
        setUserProfileURL(userProfileURL)
    }, [userId])
  
    const shareButton = (
    <IconButton
        onClick={showModal}
        color="secondary"
        ><IosShareIcon/>
    </IconButton>)

    let copyURL = () => {
        copyTextToClipboard(userProfileURL)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }

    if (!modalOpen) {
      return <>{shareButton}</>
    }
  
    return (
      <>
      {shareButton}
      <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description" 
              open={modalOpen}
              onClose={closeModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
              backdrop: {
                  timeout: 500,
              },
              }}
            >
              <Fade in={modalOpen}>
                  <Box sx={{...modalBoxStyle, p:0 }}>
                  <Box sx={{ p:4 }}>
                  <Typography variant='h5'>Share Profile</Typography>
                        <Stack direction={"column"}>
                            <Button sx={{marginTop:"8px"}} variant="outlined" onClick={copyURL}>{copied ? "Copied!" : "Copy Link 🔗"}</Button>
                        </Stack>
                    </Box>
                  </Box>
              </Fade>
            </Modal>
        </>
    )
  }