import React, { useContext, useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";

import { useNavigate } from "react-router-dom";
import { RecMap, deleteMap } from "../query/db/map";
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop';
import Container from '@mui/material/Container'
import { modalBoxStyle } from "../styles/themes";
import { UserContext } from "../context/userContext";
import Typography from '@mui/material/Typography'


export default function DeleteMapButton({map}:{map:RecMap}) {

    const userContext = useContext(UserContext)

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [deletingMap, setDeletingMap] = useState<boolean>(false)
    const [isFavoritesMap, setIsFavoritesMap] = useState(userContext.user?.favoritesMap === map.id)
  
    const showModal = () => { setModalOpen(true) };
    const closeModal = () => { setModalOpen(false) };
  
    const navigate = useNavigate()
  
    const deleteMapFn = () => {
      setDeletingMap(true)
      deleteMap(map.id).then(() => {
        setDeletingMap(false)
        navigate("/mymaps")
      })
    }
  
    useEffect(() => {
      if (userContext.user?.favoritesMap === map.id) {
        setIsFavoritesMap(true)
      }
    }, [userContext.user, map])
  
    const deleteButton = (
    <Button
        onClick={showModal}
        color="warning"
        disabled={isFavoritesMap}
        >Delete Map</Button>)
  
    if (isFavoritesMap) {
      return <></>
    }

    if (!modalOpen) {
      return (<>{deleteButton}</>)
    }
  
    let deleteConfirmation = (<>
      <Button onClick={deleteMapFn} color="warning" variant="contained">Delete Map</Button>
      <Button onClick={closeModal}>Cancel</Button>
      </>
    )
    if (deletingMap) {
      <CircularProgress />
    }
  
    return (
      <>
        {deleteButton}
        <Modal
            aria-labelledby="delete-confirm-modal"
            aria-describedby="delete-confirm-transition" 
            open={modalOpen}
            onClose={closeModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {timeout: 500},
            }}
            >
            <Fade in={modalOpen}>
                <Box sx={modalBoxStyle}>
                    <Typography>Are you sure you want to permanently delete this map?</Typography>
                    <Container>
                    {deleteConfirmation}
                    </Container>
                </Box>
            </Fade>
        </Modal>
        </>
    )
  }