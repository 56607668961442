import { DocumentData, QueryDocumentSnapshot, SnapshotOptions, addDoc, collection, doc, getDoc, Timestamp, getDocs, DocumentReference, where, query, orderBy, deleteDoc, updateDoc, and } from "firebase/firestore";
import { firestore } from "../../config/firebase-config";
import { UserData } from "./user";
import { generateGradient } from "../../utils/gradient";

export interface SavedPlace {
    id: string,
    place: Place,
}

export interface Place {
    name: string,
    address: string,
    tags: string[],
    pinTags: string[],
    lat: number,
    lng: number,
    pinImg: string,
    imageSrcs: string[],
    notes: string
}

export interface PlaceUpdate {
    tags?: string[],
    pinImg?: string,
    notes?: string,
    pinTags?: string[],
}

export interface RecMapBase {
    owner: string,
    permissions: "public" | "private" | "shared",
    sharedWith: string[],
    backgroundImg?: string,
    backgroundGradient: string,
    mapName: string,
    createdAt: number,
}

export interface RecMapData extends RecMapBase {
    places: SavedPlace[],
}

export interface RecMapDataUpdate {
    permissions?: "public" | "private" | "shared",
    sharedWith?: string[],
    backgroundImg?: string,
    backgroundGradient?: string,
    mapName?: string,
}

export interface RecMap {
    id: string,
    data: RecMapBase,
    places?: SavedPlace[],
}

export type OptionalRecMap = RecMap | null

const mapCollection = "maps"

const placesSubCollection = "/places"

export const palettes = [
    [
        "#D9ED92",
        "#B5E48C",
        "#99D98C",
        "#76C893",
        "#52B69A",
        "#34A0A4",
        "#168AAD",
        "#1A759F",
        "#1E6091",
        "#184E77",
    ],
    [
        "#7400b8",
        "#6930c3",
        "#5e60ce",
        "#5390d9",
        "#4ea8de",
        "#48bfe3",
        "#56cfe1",
        "#64dfdf",
        "#72efdd",
        "#80ffdb"
    ],
    [
        "#ffcbf2",
        "#f3c4fb",
        "#ecbcfd",
        "#e5b3fe",
        "#e2afff",
        "#deaaff",
        "#d8bbff",
        "#d0d1ff",
        "#c8e7ff",
        "#c0fdff"
    ]
]

export function createEmptyMap(owner:string, mapName:string):RecMapData {
    let randomGradient = generateGradient()
    return {
        owner: owner,
        permissions: "private",
        sharedWith: [],
        backgroundGradient: randomGradient,
        mapName: mapName,
        createdAt: Date.now(),
        places: [],
    }
}

const mapDataConverter = {
    toFirestore(mapData: RecMapBase): DocumentData {
      return {
        owner: mapData.owner,
        permissions: mapData.permissions,
        sharedWith: mapData.sharedWith,
        backgroundGradient: mapData.backgroundGradient,
        mapName: mapData.mapName,
        createdAt: Timestamp.fromMillis(mapData.createdAt),
      };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): RecMapBase {
        const data = snapshot.data(options)!;
        let mapData = {
            owner: data.owner,
            permissions: data.permissions,
            sharedWith: data.sharedWith,
            backgroundGradient: data.backgroundGradient,
            mapName: data.mapName,
            createdAt: (data.createdAt as Timestamp).toMillis(),
        }
        return mapData
    }
};

const placeConverter = {
    toFirestore(place: Place): DocumentData {
        return {
            name: place.name,
            address: place.address,
            tags: place.tags,
            lat: place.lat,
            lng: place.lng,
            pinImg: place.pinImg,
            imageSrcs: place.imageSrcs,
            notes: place.notes,
            pinTags: place.pinTags,
        };
      },
      fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
      ): Place {
        const data = snapshot.data(options)!;
        return {
            name: data.name,
            address: data.address,
            tags: data.tags,
            lat: data.lat,
            lng: data.lng,
            pinImg: data.pinImg,
            imageSrcs: data.imageSrcs,
            notes: data.notes,
            pinTags: data.pinTags,
        }
      }
}

function getPlaceUpdate(update:PlaceUpdate) {
    let firestoreUpdate = {}
    if (update.tags) {
        firestoreUpdate = {...firestoreUpdate, tags: update.tags}
    }
    if (update.notes) {
        firestoreUpdate = {...firestoreUpdate, notes: update.notes}
    }
    if (update.pinImg) {
        firestoreUpdate = {...firestoreUpdate, pinImg: update.pinImg}
    }
    if (update.pinTags) {
        firestoreUpdate = {...firestoreUpdate, pinTags: update.pinTags}
    }
    return firestoreUpdate
}

function getMapDataUpdate(update:RecMapDataUpdate) {
    let firestoreUpdate = {}
    if (update.mapName) {
        firestoreUpdate = {...firestoreUpdate, mapName: update.mapName}
    }
    if (update.backgroundGradient) {
        firestoreUpdate = {...firestoreUpdate, backgroundGradient: update.backgroundGradient}
    }
    if (update.backgroundImg) {
        firestoreUpdate = {...firestoreUpdate, notes: update.backgroundImg}
    }
    if (update.permissions) {
        firestoreUpdate = {...firestoreUpdate, permissions: update.permissions}
    }
    if (update.sharedWith) {
        firestoreUpdate = {...firestoreUpdate, sharedWith: update.sharedWith}
    }
    return firestoreUpdate
}

async function populatePlaces(recMap:RecMap):Promise<void> {
    let placesCol = collection(firestore, mapCollection, recMap.id, placesSubCollection).withConverter(placeConverter)
    let places = await getDocs(placesCol)
    let newPlaces:SavedPlace[] = []
    places.forEach(placeDoc => {
        let place = placeDoc.data()
        let savedPlace = {place: place, id: placeDoc.id}
        if (place && placeDoc.exists()) {
            newPlaces.push(savedPlace)
        }
    })
    recMap.places = newPlaces
}

export async function saveNewMap(map:RecMapData):Promise<RecMap> {
    const mapCol = collection(firestore, mapCollection).withConverter(mapDataConverter)
    const mapDoc = await addDoc(mapCol, map)
    let placePromises:Promise<DocumentReference<Place>>[] = []
    let placesCol = collection(firestore, mapCollection, mapDoc.id, placesSubCollection).withConverter(placeConverter)
    map.places.forEach(place => {
        placePromises.push(addDoc(placesCol, place.place))
    })
    await Promise.all(placePromises)
    return {
        id: mapDoc.id,
        data: map
    }
}

export async function updatePlace(map:RecMap, place:SavedPlace, placeUpdate:PlaceUpdate):Promise<SavedPlace> {
    if (!place.id) {
        throw new Error("No place id on update")
    }
    const placeCol = doc(firestore, mapCollection, map.id, placesSubCollection, place.id)
    await updateDoc(placeCol, getPlaceUpdate(placeUpdate))
    place.place = {...place.place, ...placeUpdate}
    let newPlace = {id: place.id, place: place.place}
    let newPlaces = []
    map.places?.forEach(p => {
        if (p.id !== place.id) {
            newPlaces.push(p)
        }
    })
    newPlaces.push(newPlace)
    map.places = newPlaces
    return newPlace
}

export async function addPlace(map:RecMap, place:Place):Promise<SavedPlace> {
    const placeCol = collection(firestore, mapCollection, map.id, placesSubCollection)
    const convertedPlace = placeConverter.toFirestore(place)
    convertedPlace.owner = map.data.owner
    const placeDoc = await addDoc(placeCol, convertedPlace)
    let savedPlace = {id: placeDoc.id, place: place}
    map.places = [...(map.places||[]), savedPlace]
    return savedPlace
}

export async function copyToFavorites(user:UserData, place:Place):Promise<void> {
    const placeCol = collection(firestore, mapCollection, user.favoritesMap, placesSubCollection)
    let q = query(placeCol, where("name", "==", place.name), where("address", "==", place.address))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.empty) {
        let placeCopy = placeConverter.toFirestore({
            name: place.name,
            address: place.address,
            tags: [],
            pinTags: [],
            lat: place.lat,
            lng: place.lng,
            pinImg: "",
            imageSrcs: [],
            notes: ""
        })
        placeCopy.owner = user.user.uid
        await addDoc(placeCol, placeCopy)
    }
}

export async function deletePlace(map:RecMap, placeId:string):Promise<void> {
    let placesDoc = doc(firestore, mapCollection, map.id, placesSubCollection, placeId)
    await deleteDoc(placesDoc)
    map.places = (map.places||[]).filter((p) => {
        return p.id !== placeId
    })
    return
}

export async function deleteMap(mapId:string):Promise<void> {
    let placesCol = collection(firestore, mapCollection, mapId, placesSubCollection).withConverter(placeConverter)
    let placesDoc = await getDocs(placesCol)
    let placeDeletePromises:Promise<void>[] = []
    placesDoc.forEach(placeDoc => {
        placeDeletePromises.push(deleteDoc(placeDoc.ref))
    })
    await Promise.all(placeDeletePromises)
    const mapRef = doc(firestore, mapCollection, mapId).withConverter(mapDataConverter)
    await deleteDoc(mapRef)
    return
}

export async function updateMap(map:RecMap, update:RecMapDataUpdate):Promise<RecMap> {
    if (!map.id) {
        throw new Error("No map id on update")
    }
    const mapCol = doc(firestore, mapCollection, map.id)
    await updateDoc(mapCol, getMapDataUpdate(update))
    
    let m = {id: map.id, data: {...map.data, ...update}, places: map.places}
    return m
}

export async function getMyMaps(userId:string):Promise<RecMap[]> {
    let mapsCol = collection(firestore, mapCollection).withConverter(mapDataConverter)
    let q = query(mapsCol, where("owner", "==", userId), orderBy("createdAt", "desc"))
    const querySnapshot = await getDocs(q);
    let maps:RecMap[] = []
    querySnapshot.forEach((doc) => {
        let recMap = {
            id: doc.id,
            data: doc.data(),
        }
        maps.push(recMap)
    });
    return maps
}

export async function getMap(id:string):Promise<OptionalRecMap> {
    const mapRef = doc(firestore, mapCollection, id).withConverter(mapDataConverter)
    let mapDataDoc = await getDoc(mapRef)
    let mapData = mapDataDoc.data()
    if (!mapData) {
        return null
    }
    let recMap = {
        id: id,
        data: mapData,
    }
    await populatePlaces(recMap)
    return recMap
}

async function getBaseMap(id:string):Promise<OptionalRecMap> {
    const mapRef = doc(firestore, mapCollection, id).withConverter(mapDataConverter)
    let mapDataDoc = await getDoc(mapRef)
    let mapData = mapDataDoc.data()
    if (!mapData) {
        return null
    }
    let recMap = {
        id: id,
        data: mapData,
    }
    return recMap
}

export async function getMaps(ids:string[]):Promise<OptionalRecMap[]> {
    let mapPromises:Promise<RecMap | null>[] = []
    ids.forEach(mapId => {
        mapPromises.push(getBaseMap(mapId))
    });
    return await Promise.all(mapPromises)
}

function notNull(value: OptionalRecMap): value is RecMap {
    return value !== null;
}

export function removeNull(maps:OptionalRecMap[]):RecMap[] {
    return maps.filter(notNull)
}

export async function getUserPublicMaps(userId:string):Promise<OptionalRecMap[]> {
    let maps:RecMap[] = []
    const mapsRef = collection(firestore, mapCollection)
    const q = query(mapsRef, and(where("permissions", "==", "public"), where("owner", "==", userId)))
    const querySnap = await getDocs(q)
    querySnap.forEach((doc) => {
        const recMap = mapDataConverter.fromFirestore(doc, {})
        maps.push({
            id: doc.id,
            data: recMap,
        })
    })
    return maps
}
