import React, { Component, useEffect, useState } from 'react'
import { auth, firestoreFns } from '../config/firebase-config'
import { httpsCallable } from 'firebase/functions'


interface MapTokenContextType {
  token: string | null,
  loadingToken: boolean,
  mapkitLoaded: boolean,
  mapkitLoadFailed: boolean,
}

const MapTokenContext = React.createContext<MapTokenContextType>(
  { 
    token: null,
    loadingToken: false,
    mapkitLoaded: false,
    mapkitLoadFailed: false,
  }
)

function MapTokenProvider({children}:any) {
    // Context state
    let [ token, setToken] = useState<string|null>(null)
    let [ loadingToken, setLoadingToken] = useState(false)
    let [ mapkitLoadFailed, setMapkitLoadFailed] = useState(false)
    let [ mapkitLoaded, setMapkitLoaded] = useState(false)

    const getMapToken = httpsCallable(firestoreFns, 'getMapToken')

    useEffect(() => {
        setLoadingToken(true)
        getMapToken().then((tokenData) => {
            let token = (tokenData.data as {token:string}).token
            setToken(token)
            setLoadingToken(false)
          })
    }, [])

    useEffect(() => {
      if (!token) {
          return
      }
      if (mapkitLoaded) {
        return
      }
      (window as any).initMapKit = () => {
        mapkit.init({
            authorizationCallback: function(done) {
                getMapToken().then(t => {
                  done((t.data as {token:string}).token)
                })
            },
            language: "en"
        });
        setMapkitLoaded(true)
      }
      const script = document.createElement("script");
      script.setAttribute("src", "https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.core.js");
      script.async = true
      script.crossOrigin = 'anonymous'
      script.setAttribute("data-callback", "initMapKit")
      script.setAttribute("data-libraries", "services,full-map,geojson")
      script.setAttribute("data-initial-token", token)
      script.onerror = () => { setMapkitLoadFailed(true) }
      document.head.appendChild(script);
  
      return () => {
          document.head.removeChild(script);
          setMapkitLoaded(false)
      };
    }, [token]);

    return (
        <MapTokenContext.Provider
        value={{
            token: token,
            loadingToken: loadingToken,
            mapkitLoaded: mapkitLoaded,
            mapkitLoadFailed: mapkitLoadFailed,
        }}
        >
        {children}
        </MapTokenContext.Provider>
    )
}

export { MapTokenProvider, MapTokenContext }