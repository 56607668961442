import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useState, useContext, useEffect } from 'react';

import { SkeletonMapTile, boxStyle } from './maptile';
import { coreTheme } from '../styles/themes';
import { UserContext } from '../context/userContext';
import useWidth from '../hooks/windowWidth';
import { Box, Card, ImageListItemBar } from '@mui/material';
import { userPageBase } from '../pages/usermaps';
import { useNavigate } from 'react-router';
import { getUserDisplayInfo } from '../query/db/user';


const cardStyle = {
    boxShadow: 'none',
    cursor: 'pointer',
}

type UserTileProps = {userId:string, displayName:string, backgroundGradient:string|undefined}

function UserTile({userId, displayName, backgroundGradient}:UserTileProps) {
    const navigate = useNavigate()

    let navigateToProfile = () => {
        let mapPath = userPageBase + userId
        navigate(mapPath)
    }

    return (
<Card onClick={navigateToProfile} sx={cardStyle}>
            <Box sx={{...boxStyle, background: backgroundGradient}}/>
            <ImageListItemBar 
                title={displayName}
                position="below"
                sx={{ paddingLeft: "8px"}}
            />
        </Card>
    )
}


export default function FollowsProfileList() {
    let [users, setUsers] = useState<UserTileProps[]|null>(null);
    let userCtx = useContext(UserContext)

    let windowWidth = useWidth()

    useEffect(() => {
        if (userCtx.user?.follows) {
            const displayPromises:Promise<UserTileProps>[] = []
            userCtx.user.follows.forEach((id) => {
                displayPromises.push(
                    getUserDisplayInfo(id)
                    .then(
                        (info => {
                            return {userId: id, displayName: info.displayName, backgroundGradient: info.profileGradient}
                        })
                    )
                )
            })
            Promise.all(displayPromises).then((displayInfo) => {
                setUsers(displayInfo)
            })
        } else {
            if (!userCtx.loadingUser) {
                setUsers([])
            }
        }
    }, [userCtx, userCtx.user, userCtx.user?.follows])
    
    let colCount = 4

    if (windowWidth && windowWidth < (coreTheme.breakpoints.values.lg)) {
        colCount = 3
    }
    if (windowWidth && windowWidth < (coreTheme.breakpoints.values.md)) {
        colCount = 2
    }
    if (windowWidth && windowWidth < (coreTheme.breakpoints.values.sm)) {
        colCount = 1
    }

    if (!users || userCtx.loadingUser) {
        return (
            <ImageList cols={colCount} gap={Number(24)} >
                {[1, 2, 3, 4].map((i: number) => <ImageListItem key={i} ><SkeletonMapTile/></ImageListItem>)}
            </ImageList>
        )
    }
    
    return (
        <ImageList cols={colCount} gap={Number(24)} >
            {users.map((props: UserTileProps) => <ImageListItem key={props.userId} ><UserTile {...props} /></ImageListItem>)}
        </ImageList>
    );
}