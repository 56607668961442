import { useContext, useEffect, useState } from "react";

import { updateProfile } from "firebase/auth";

import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Container from '@mui/material/Container';

import { UserContext } from "../context/userContext";
import { Navigate } from "react-router-dom";
import { defaultUserGradient, setProfileGradient } from "../query/db/user";
import { Box, Button, Stack } from "@mui/material";
import { generateGradient } from "../utils/gradient";


export default function Profile() {
    const context = useContext(UserContext)
    const [updateGradientValue, setUpgradeGradientValue] = useState(context.user?.profileGradient)

    useEffect(() => {
        setUpgradeGradientValue(context.user?.profileGradient || defaultUserGradient)
    }, [context.user, context.user?.profileGradient])

    let saveName = (e: any) => {
        if (context?.user ) {
            if (e.target.value !== context?.user?.user.displayName) {
                updateProfile(context?.user.user, {
                    displayName: e.target.value,
                  }).catch((error) => {
                    console.log(error)
                  }).finally(() => {
                    context.refreshUser()
                  });
            }
        }
    };
    let saveProfileGradient = (gradient:string|undefined) => {
        if (context?.user && gradient) {
            if (gradient !== context?.user?.profileGradient) {
                setProfileGradient(context.user, gradient).catch((error) => {
                    console.log(error)
                  }).finally(() => {
                    context.refreshUser()
                  });
            }
        }
    };

    if (context?.loadingUser) {
        return (
            <Container maxWidth="lg">
                <CircularProgress />
            </Container>
        )
    }

    const genNewGradient = () => {
        setUpgradeGradientValue(generateGradient())
    }

    const cancelNewGradient = () => {
        setUpgradeGradientValue(context.user?.profileGradient || defaultUserGradient)
    }

    if (!context?.user) {
        return ( <Navigate to="/"/> )
    }

    let displayName = ""
    if (context?.user?.user.displayName) {
        displayName = context.user.user.displayName
    }

    let displayPhone = ""
    if (context?.user?.user.phoneNumber) {
        displayPhone = context?.user.user.phoneNumber
    }
    
    return (    
    <div id="Profile">
        <Container maxWidth="lg">
            <div>
            <h2>Name</h2><TextField id="name" onBlur={saveName} defaultValue={displayName} />
            </div>
        </Container>
        <Container maxWidth="lg">
            <div>
            <h2>Phone Number</h2><TextField id="phone" variant="filled" disabled={true} defaultValue={displayPhone} placeholder="No Phone Number"/>
            </div>
        </Container>
        <Container maxWidth="lg">
            <h2>Profile Display</h2>
            <Stack direction={"row"}>
                <Box sx={{background: updateGradientValue, height:"40px", width: "80px", marginRight: "8px", borderRadius:"12px"}}/>
                <Button variant='outlined' onClick={genNewGradient}>Regenerate</Button>
                { (context.user.profileGradient || defaultUserGradient) !== updateGradientValue &&
                    (<><Button onClick={() => saveProfileGradient(updateGradientValue)}>Save</Button><Button onClick={cancelNewGradient}>Canel</Button></>)
                }
            </Stack>
        </Container>
    </div>
    );
}