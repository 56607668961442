import { palettes } from "../query/db/map";


export function generateGradient() {
    // choose palette
    let palette = palettes[Math.floor(Math.random() * palettes.length)];
    // 2 to 3 steps
    let numSteps = 2 + Math.floor(Math.random() * 2);
    let direction = Math.floor(Math.random() * 360);
    let steps = [];
    let step = 0;
    let cumStepPos = 0;
    while (step < numSteps) {
        step += 1;
        cumStepPos += Math.floor(Math.random() * 51);
        if (cumStepPos >= 100) cumStepPos = 100;
        steps.push(" " + palette[Math.floor(Math.random() * palette.length)] + " " + cumStepPos + "%");
    }
    let stepsStr = steps.join(",");
    return "linear-gradient(" + direction + "deg," + stepsStr + ")";
}
