import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface CustomTheme extends Theme {
    // Add custom fields here which will be as accessible from the theme
    // generally
  }
  // allow configuration using `createTheme`
  interface CustomThemeOptions extends ThemeOptions {
    // Add matching optional custom fields here
  }
  export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

export const themeOptions:ThemeOptions = {
    palette: {
      primary: {
        main: '#4361ee',
      },
      secondary: {
        main: '#000000',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: 'Karla',
      button: {
        textTransform: 'none'
      },
      h6: {
        fontSize: '1rem',
        lineHeight: 1.3,
        paddingBottom: '4px',
        fontWeight: 700,
      },
      h5: {
        fontSize: '1.2rem',
        lineHeight: 1.3,
        paddingBottom: '4px',
        fontWeight: 700,
      },
      h1: {
        fontSize: '2.3rem',
        lineHeight: 1.3,
        paddingBottom: '4px',
        fontWeight: 700,
      },
      h2: {
        fontSize: '1.4rem',
        lineHeight: 1.3,
        paddingBottom: '4px',
        fontWeight: 700,
      },
      subtitle2: {
        lineHeight: 1.3,
        fontSize: '.875rem',
      },
      body1: {
        fontSize: '1rem',
      },
      body2: {
        fontSize: '0.875rem',
      }
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#ffffff',
          },
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
            //boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.15)",
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize:'1rem',
            lineHeight: '1.3rem',

          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
          }
        }
      }
    },
  };

export const savedPlaceColor = "#7b90f3"

export const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '24px',
  boxShadow: 24,
  p: 4,
};
  
export const coreTheme = createTheme(themeOptions);