import { useEffect, useState, useRef, useContext } from "react";
import { generate as randString } from "randomstring"

import Skeleton from "@mui/material/Skeleton";
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import { Place, RecMap, SavedPlace } from "../../query/db/map";
import { MapTokenContext } from "../../context/mapToken";
import { toMapkitRegion, toRegion } from "../../query/search/appleSearch";
import { Region, SearchResult, regionsEqual } from "../../query/search/common";
import { coreTheme, savedPlaceColor } from "../../styles/themes";
import { placeCardMaxWidth } from "../placeCard";
import useWidth from "../../hooks/windowWidth";
import { getBounds, getDistance } from "geolib";


const titleVisDistance = 0.1

const addPlaceSvg = '/addPlace.svg';
const savedPlaceSvg = '/savedPlace.svg'


interface Annotation { id: string, annotation: mapkit.Annotation }
interface SavedAnnotation extends Annotation { glyph:string }

export interface AppleMapProps {
    recMap: RecMap,
    selectedPlace: SearchResult | SavedPlace | null,
    viewRegion: Region | null,
    setViewRegion: (r: Region) => void
    setSelectedPlace: (place: SearchResult | SavedPlace) => void
    searchResults: SearchResult[]
    filteredPlaces: SavedPlace[]
}

function getLatLngAdjustments(width:number|undefined, lat:number, radius:number) {
    let addLng = 0
    let addLat = 0
    if (width && width > coreTheme.breakpoints.values.sm) {
        let moveDistance = radius * .25
        addLng = -1 * moveDistance / (111320 * Math.cos((lat*Math.PI / 180))) // 111.320*cos(latitude) km
    } else {
        let moveDistance = radius * .15
        addLat = -1 * moveDistance / 110574 // 110.574 km = 1 deg lat
    }
    return {addLat, addLng}
}

export default function AppleMap(
    {
        recMap,
        selectedPlace,
        searchResults,
        filteredPlaces,
        viewRegion,
        setViewRegion,
        setSelectedPlace,
    }: AppleMapProps) {

    let mapTokenCtx = useContext(MapTokenContext)

    let [mapElement, setMapElement] = useState<mapkit.Map | null>(null)
    let [searchAnnotations, setSearchAnnotations] = useState<Annotation[]>([])
    let [savedPlacesAnnotations, setSavedPlaceAnnotations] = useState<SavedAnnotation[]>([])
    let [zoomRadius, setZoomRadius] = useState(0)
    let width = useWidth()

    const ref = useRef<HTMLDivElement>(null);

    function getSavedPlaceAnnotations(places: SavedPlace[], mapElementGetter: () => mapkit.Map | null) {
        return places.map(res => {
            let coordinate = new mapkit.Coordinate(res.place.lat, res.place.lng)
            let options: mapkit.MarkerAnnotationConstructorOptions = {
                draggable: false,
                enabled: true,
                color: savedPlaceColor,
                title: res.place.name,
                titleVisibility: mapkit.FeatureVisibility.Adaptive,
                displayPriority: mapkit.Annotation.DisplayPriority.Required,
                collisionMode: mapkit.Annotation.CollisionMode.None,
                calloutEnabled: false,
            }
            if (res.place.pinImg) {
                options = {...options, glyphText: res.place.pinImg}
            } else {
                options = {...options, glyphImage: { 1: savedPlaceSvg }}
            }
            let annotation = new mapkit.MarkerAnnotation(coordinate, options)
            let onSelectCallback = (e: mapkit.EventBase<mapkit.Map>) => {
                let localMapElement = mapElementGetter()
                if (localMapElement) {
                    const {addLat, addLng} = getLatLngAdjustments(width, res.place.lat, localMapElement.region.radius)
                    var newCenter = new mapkit.Coordinate(res.place.lat + addLat, res.place.lng + addLng);
                    localMapElement?.setCenterAnimated(newCenter)
                }
                setSelectedPlace(res)
            }
            annotation.addEventListener("select", onSelectCallback)
            return { id: res.id, annotation: annotation, glyph: res.place.pinImg }
        })
    }

    useEffect(() => {
        if (mapElement) {
            let allAnnotations: mapkit.Annotation[] = [...savedPlacesAnnotations.map(a => a.annotation), ...searchAnnotations.map(a => a.annotation)]
            let maxDistance = zoomRadius * titleVisDistance
            for (let i = 0; i < allAnnotations.length; i++) {
                const a1 = allAnnotations[i];
                let collided = false
                for (let j = i + 1; j < allAnnotations.length; j++) {
                    const a2 = allAnnotations[j];
                    let distance = getDistance(a1.coordinate, a2.coordinate)
                    if (distance < maxDistance) {
                        // Hack because title visibility isnt settable :(
                        a1.subtitle = a1.subtitle || a1.title
                        a1.title = ''
                        a2.subtitle = a2.subtitle || a2.title
                        a2.title = ''
                        collided = true
                        break
                    }
                }
                if (!collided && a1.subtitle) {
                    a1.title = a1.subtitle
                }
            }
        }
    }, [savedPlacesAnnotations, searchAnnotations, zoomRadius, mapElement])

    useEffect(() => {
        if (mapElement && viewRegion) {

            mapElement.setRegionAnimated(toMapkitRegion(viewRegion))
        }
    }, [viewRegion])

    useEffect(() => {
        if (mapElement) {
            if (selectedPlace && (selectedPlace as SavedPlace).id) {
                let id = (selectedPlace as SavedPlace).id
                let matchedAnnotation = savedPlacesAnnotations.find(a => a.id == id)
                if (matchedAnnotation) {
                    matchedAnnotation.annotation.selected = true
                    mapElement.selectedAnnotation = matchedAnnotation.annotation
                }
                return
            }
            if (!selectedPlace && mapElement.selectedAnnotation) {
                mapElement.selectedAnnotation.selected = false
                mapElement.selectedAnnotation = null
            }
        }
    }, [selectedPlace])

    useEffect(() => {
        if (mapElement) {
            const savedAnnotationByIds:Map<string,SavedAnnotation> = new Map()
            savedPlacesAnnotations.forEach((a) => {
                savedAnnotationByIds.set(a.id, a);
            });
            const savedPlacesById:Map<string,SavedPlace> = new Map();
            (recMap.places||[]).forEach((p) => {
                savedPlacesById.set(p.id, p);
            });
            let newPlaces = (recMap.places||[]).filter(p => {
                const a = savedAnnotationByIds.get(p.id)
                if (a) {
                    return a.glyph !== p.place.pinImg
                }
                return true
            })
            let keptAnnotations:SavedAnnotation[] = []
            let removeAnnotations = savedPlacesAnnotations.filter(
                a => {
                    const p = savedPlacesById.get(a.id)
                    // if a isnt in saved places anymore, dont keep it
                    let keep = false
                    if (p) {
                        keep = a.glyph === p.place.pinImg
                    }
                    if (keep) {
                        keptAnnotations.push(a)
                    }
                    return !keep
                }
            )
            let newAnnotations = getSavedPlaceAnnotations(newPlaces, () => { return mapElement })
            let joinedAnnotations = [...keptAnnotations, ...newAnnotations]
            mapElement.removeAnnotations(removeAnnotations.map(a => a.annotation))
            mapElement.addAnnotations(newAnnotations.map(a => a.annotation))
            setSavedPlaceAnnotations(joinedAnnotations)
        }
    }, [recMap, recMap.places, mapElement])

    let createMapElement = (elemRef: HTMLDivElement, map: RecMap, selectedPlace: SearchResult | SavedPlace | null) => {
        let mapOptions: mapkit.MapConstructorOptions = {
            showsCompass: mapkit.FeatureVisibility.Hidden,
            showsMapTypeControl: false,
            showsUserLocation: true,
            tracksUserLocation: false,
        }
        let mapElemContainer: { mapElement: mapkit.Map | null } = { mapElement: null }
        if (selectedPlace) {
            let center = new mapkit.Coordinate(selectedPlace.place.lat, selectedPlace.place.lng)
            mapOptions = { ...mapOptions, center: center }
        }
        if (!map.places || (map.places || []).length === 0) {
            mapOptions = { ...mapOptions, showsUserLocation: true, tracksUserLocation: true, }
        } else {
            if (map.places.length === 1) {
                let place = map.places[0].place
                let center = new mapkit.Coordinate(place.lat, place.lng)
                mapOptions = { ...mapOptions, region: new mapkit.CoordinateRegion(center, new mapkit.CoordinateSpan(0.1, 0.1)) }
            } else {
                let latlngs = map.places.map(p => { return { latitude: p.place.lat, longitude: p.place.lng } })
                let bounds = getBounds(latlngs)
                var boundingRegion = new mapkit.BoundingRegion(bounds.maxLat, bounds.maxLng, bounds.minLat, bounds.minLng);
                let region = boundingRegion.toCoordinateRegion()
                region.span = new mapkit.CoordinateSpan(1.2*region.span.latitudeDelta, 1.2*region.span.longitudeDelta)
                mapOptions = { ...mapOptions, region: region }
            }
            mapOptions = { ...mapOptions}
        }

        let m:mapkit.Map = new mapkit.Map(
            elemRef, mapOptions
        )
        // @ts-ignore
        m._allowWheelToZoom = true
        mapElemContainer.mapElement = m
        m.addEventListener('region-change-end', (event) => {
            let newRegion = toRegion(event.target.region.toBoundingRegion())
            if (!regionsEqual(viewRegion, newRegion)) {
                setViewRegion(newRegion)
            }
        })
        m.addEventListener('zoom-end', (event) => {
            setZoomRadius(event.target.region.radius)
        })
        return m
    }

    useEffect(() => {
        if (mapTokenCtx.mapkitLoaded) {
            let m = createMapElement(ref.current!, recMap, selectedPlace)
            setZoomRadius(m.region.radius)
            setMapElement(m)
        }
    }, [mapTokenCtx.mapkitLoaded])

    useEffect(() => {
        if (mapElement) {
            let annotationIds = searchAnnotations.map(a => a.id)
            let resultIds = searchResults.map(s => s.itemId)
            let newResults = searchResults.filter(s => !annotationIds.includes(s.itemId))
            let removeAnnotations = searchAnnotations.filter(a => !resultIds.includes(a.id))
            let newAnnotations = newResults.map(res => {
                let coordinate = new mapkit.Coordinate(res.place.lat, res.place.lng)
                let options: mapkit.MarkerAnnotationConstructorOptions = {
                    draggable: false,
                    enabled: true,
                    color: coreTheme.palette.secondary.main,
                    title: res.place.name,
                    titleVisibility: mapkit.FeatureVisibility.Adaptive,
                    displayPriority: mapkit.Annotation.DisplayPriority.Required,
                    collisionMode: mapkit.Annotation.CollisionMode.None,
                    calloutEnabled: false,
                    subtitleVisibility: mapkit.FeatureVisibility.Hidden,
                    glyphImage: { 1: addPlaceSvg },
                }
                let annotation:mapkit.Annotation = new mapkit.MarkerAnnotation(coordinate, options)
                let onSelectCallback = (e: mapkit.EventBase<mapkit.Map>) => {
                    if (mapElement) {
                        const {addLat, addLng} = getLatLngAdjustments(width, res.place.lat, mapElement?.region.radius)
                        var newCenter = new mapkit.Coordinate(res.place.lat + addLat, res.place.lng + addLng);
                        mapElement.setCenterAnimated(newCenter)
                    }
                    setSelectedPlace(res)
                }
                annotation.addEventListener("select", onSelectCallback)
                return { annotation: annotation, id: res.itemId}
            })
            let joinedAnnotations = [...searchAnnotations.filter(a => resultIds.includes(a.id)), ...newAnnotations]
            mapElement.removeAnnotations(removeAnnotations.map(a => a.annotation))
            mapElement.addAnnotations(newAnnotations.map(a => a.annotation))
            setSearchAnnotations(joinedAnnotations)
        }
    }, [searchResults, mapElement])

    useEffect(() => {
        const filteredPlaceMap:Map<string,SavedPlace> = new Map()
        filteredPlaces.forEach((p) => {
            filteredPlaceMap.set(p.id, p);
        });
        savedPlacesAnnotations.forEach(a => {
            if (filteredPlaceMap.has(a.id)) {
                a.annotation.visible = true
            } else {
                a.annotation.visible = false
            }
        })
    }, [filteredPlaces, savedPlacesAnnotations])

    let waitingOnMapElem = (<Skeleton animation="wave" variant="rectangular" width="100%" height="100%" />)
    if (mapTokenCtx.mapkitLoadFailed) {
        waitingOnMapElem = (
            <Box width="100%" height="100%">
                <Alert severity="error" sx={{ height: "inherit" }}>Map component failed to load. Please try again later.</Alert>
            </Box>
        )
    }

    return (
        <>
            {!mapElement && waitingOnMapElem}
            <div ref={ref} id="map" style={{ height: '100%', width: '100%', flex: (mapElement ? 1 : 0) }} />
        </>
    )
}