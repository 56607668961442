// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth"
import "firebase/compat/auth";
import compatApp from "firebase/compat/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectStorageEmulator, getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDY82Ia8RWF-b5VUjWAMfN9jkFTx5R9vlw",
  authDomain: "spotmap.app",
  projectId: "proud-research-390418",
  storageBucket: "proud-research-390418.appspot.com",
  messagingSenderId: "35907511138",
  appId: "1:35907511138:web:97dd093ba3b901e9cf023b",
  measurementId: "G-Z7RYV6ZSN1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app)
compatApp.initializeApp(firebaseConfig);
export const authForFirebaseUI = compatApp.auth()
setPersistence(auth, browserLocalPersistence);
export const firestore = getFirestore(app)
export const firestoreFns = getFunctions(app)
export const firebaseStorage = getStorage(app)

if (process.env.NODE_ENV === 'development') {
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080)
  connectFunctionsEmulator(firestoreFns, '127.0.0.1', 5001)
  connectStorageEmulator(firebaseStorage, '127.0.0.1', 9199)
} else {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Lc7q1AnAAAAAFE2nKGUbG8rfeBTVwqoMft6DXbQ'),
  
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
}