import { useContext, useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { RecMap, getMap, SavedPlace } from "../query/db/map";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import { coreTheme } from "../styles/themes";
import PlacesList, { onFilterPlacesFn, selectPlaceFn } from "../components/placesList";
import useWidth from "../hooks/windowWidth";
import { styled } from "@mui/styles";
import Global from "@mui/styled-engine-sc/GlobalStyles/GlobalStyles";
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import {isMobile} from 'react-device-detect';
import AppleMap from "../components/maps/appleMap";
import { AutocompleteInput, AutocompleteInputProps } from "../components/search/appleAutocomplete";
import { SearchResult } from "../query/search/common";
import { Region } from "../query/search/common";
import PlaceCardOverlay from "../components/placeCard";
import useUserOwnsMap from "../hooks/userOwnsMap";
import ShareMapButton from "../components/shareMapButton";
import EditIcon from '@mui/icons-material/Edit';
import MapNameTitle from "../components/mapTitle";
import EditMapDetailsButton from "../components/editMapDetails";
import IconButton from "@mui/material/IconButton";
import DoneIcon from '@mui/icons-material/Done';
import { UserContext } from "../context/userContext";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { favoriteMap as bookmarkMap, unfavoriteMap as unbookmarkMap } from "../query/db/user";
import Helmet from "react-helmet"

export const mapBasePath = "/map/id/"
export const mapEditPath = "/edit"
export const mapOverlayZIndex = 1000

const sideListWidth = 240;
const drawerBleeding = 53

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: coreTheme.palette.mode === 'light' ? '#fff' : coreTheme.palette.grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: coreTheme.palette.mode === 'light' ? coreTheme.palette.grey[300] : coreTheme.palette.grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function PlaceDrawer({map, filterPlaces, onSelectPlace, onFilterPlaces}:{map:RecMap, filterPlaces:SavedPlace[], onSelectPlace:selectPlaceFn, onFilterPlaces:onFilterPlacesFn}) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    if (newOpen) {
      
    }
    setOpen(newOpen);
  };

  const closeAndSelect:selectPlaceFn = (index, place) => {
    setOpen(false)
    onSelectPlace(index, place)
  }
  let countStr = "Places (" + (map.places||[]).length + ")"
  if (filterPlaces.length !== map.places?.length) {
    countStr = "Places (Viewing " + filterPlaces.length + "/" + (map.places||[]).length + ")"
  }

  return (
      <>
      <Global
      styles={{
        '.MuiDrawer-root > .MuiPaper-root': {
          height: `calc(100% - ${drawerBleeding}px)`,
          overflow: 'visible',
        },
      }}
    />
      <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      allowSwipeInChildren={true}
    >
      <StyledBox
        sx={{
          position: 'absolute',
          top: -drawerBleeding,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
        }}
      >
        <Puller />
        <Stack direction="row">
          <Typography flex={1} sx={{ p: 2, color: 'text.secondary' }}>{countStr}</Typography>
          { !isMobile && <Button onClick={toggleDrawer(!open)}>{open ? "Close" : "Open"}</Button>}
        </Stack>
      </StyledBox>
      <StyledBox
        flex={1}
        display={"flex"}
        sx={{
          px: 2,
          pb: 2,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Box flex={1} display={"flex"}>
          <PlacesList places={(map.places||[])} onSelectItem={closeAndSelect} onFilterPlaces={onFilterPlaces} inDrawer={true}/>
        </Box>
      </StyledBox>
    </SwipeableDrawer>
    </>
  )
}

function SearchInputOverlay(props: AutocompleteInputProps) {
  return (
    <Box 
    position={'absolute'}
    zIndex={mapOverlayZIndex}
    top={"3%"}
    left={"3%"}
    right="3%"
    maxWidth={"450px"}
         >
          <AutocompleteInput {...props}/>
    </Box>
  )
}

export default function MapPage({editting}:{editting:boolean}) {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)

  const [map, setMap] = useState<RecMap | null>(null)
  const [loadingMap, setLoadingMap] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState<SearchResult|SavedPlace|null>(null)
  const [inEdittingMode, setInEdittingMode] = useState(editting)
  const [mapLoadError, setMapLoadError] = useState(false)
  const [viewingRegion, setViewingRegion] = useState<Region|null>(null);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [filteredPlaces, setFilteredPlaces] = useState<SavedPlace[]>(map?.places||[])
  const [inBookmarks, setBookmarked] = useState(false)

  const { mapId } = useParams<string>()
  const width = useWidth()
  const userOwnsMap = useUserOwnsMap(map)

  if (!mapId) {
    throw new Error("mapId not supplied")
  }

  useEffect(() => {
    setInEdittingMode(editting)
  }, [editting])

  let openPlaceCard:selectPlaceFn = (index, place) => {
    setSelectedPlace(place)
  }

  let filterViewPlaces:onFilterPlacesFn = (places) => {
    setFilteredPlaces(places)
  }

  useEffect(() => {
    if (userOwnsMap !== null && (inEdittingMode && !userOwnsMap)) {
      console.log("user doesnt own map")
      navigate(mapBasePath + mapId)
      setBookmarked(false)
    }
  }, [userOwnsMap, inEdittingMode, mapId, navigate])

  useEffect(() => {
    if (userCtx.user) {
      setBookmarked(userCtx.user.bookmarkedMaps.includes(mapId))
    }
    if (map?.id !== mapId && !loadingMap) {
      setLoadingMap(true)
      setSelectedPlace(null)
      getMap(mapId)
      .catch(e => {setMapLoadError(true)})
      .then(m => {
        if (!m) {
          setMapLoadError(true)
          return
        }
        setMapLoadError(false)
        setMap(m)
      }).finally(() => {
        setLoadingMap(false)
      })
    }
  }, [mapId, userCtx.user, userCtx.user?.user, loadingMap, map?.id])

  let toggleEditting = () => {
    if (inEdittingMode) {
      setSearchResults([])
      setSelectedPlace(null)
      navigate(mapBasePath + mapId)
      return
    }
    if (userOwnsMap) {
      navigate(mapBasePath + mapId + mapEditPath)
      return
    }
  }

  let toggleBookmarked = () => {
    if (userCtx.user) {
      var p:Promise<void>
      if (inBookmarks) {
        p = unbookmarkMap(userCtx.user, mapId)
      } else {
        p = bookmarkMap(userCtx.user, mapId)
      }
      p.finally(() => {
        if (userCtx.user) {
          setBookmarked(userCtx.user?.bookmarkedMaps.includes(mapId))
        }
      })
    } else {
      userCtx.triggerLogin()
    }
  }

  if (!map) {
    if (mapLoadError) {
      return (
        <Alert severity="error">This map does not exist, or you do not have permissions to view it.</Alert>
      )
    }
    return (
      <CircularProgress />
    )
  }
  let fullscreenMap = (width && width < coreTheme.breakpoints.values.sm)
  let closeCard = () => { setSelectedPlace(null) }
  let onAddPlace = (searchItemId:string, placeId:string) => { 
    setSelectedPlace((map.places||[]).find(p => p.id === placeId)||null)
    let filteredSearchResults = searchResults.filter(r => r.itemId !== searchItemId)
    setSearchResults(filteredSearchResults)
  }
  let clearSelectionAndSetSearchResults = (res:SearchResult[]) => {
    setSearchResults(res)
    setSelectedPlace(null)
  }

  return (
    <>
      <Helmet>
        <title>{map.data.mapName}</title>
      </Helmet>
      <Box display={"flex"} height={"inherit"}>
        <Stack flex={1} spacing={1}>
          <Box flex={0} paddingLeft={"8px"} paddingRight={"8px"}>
          <Stack direction="row" flex={1}>
            <Box flex={1} textAlign={"left"} alignItems={"center"} display={"flex"}>
              <MapNameTitle map={map}/>
            </Box>
            { !inEdittingMode && <ShareMapButton map={map} onMapUpdate={setMap}/> }
            {userOwnsMap && inEdittingMode && <EditMapDetailsButton map={map} onMapUpdate={setMap} />}
            {!userOwnsMap && <IconButton onClick={toggleBookmarked}>{ inBookmarks ? <BookmarkIcon /> : <BookmarkBorderIcon />}</IconButton>}
            {userOwnsMap && 
              (inEdittingMode ? 
                <IconButton color="primary" onClick={toggleEditting}><DoneIcon/></IconButton>
                :
                <IconButton onClick={toggleEditting}><EditIcon/></IconButton>
              )
            } 
          </Stack>
          </Box>
          <Box flex={1} display="flex" paddingLeft={"4px"} paddingRight={"4px"}>
          <Stack direction="row" flex={1} display={"flex"}>
            { !fullscreenMap && (
              <Box flex={1} maxWidth={sideListWidth} display={"flex"}>
              <PlacesList places={(map.places||[])} onSelectItem={openPlaceCard} onFilterPlaces={filterViewPlaces} inDrawer={false}/>
              </Box>)
            }
              <Box flex={1} display={"flex"} position={"relative"}>
                {userOwnsMap && inEdittingMode && <SearchInputOverlay map={map} viewRegion={viewingRegion} setSearchResults={clearSelectionAndSetSearchResults} setViewRegion={setViewingRegion}/>}
                {selectedPlace && <PlaceCardOverlay recMap={map} place={selectedPlace} closeFn={closeCard} onAddPlace={onAddPlace} inEdittingMode={inEdittingMode} />}
                <AppleMap 
                  recMap={map}
                  selectedPlace={selectedPlace}
                  viewRegion={viewingRegion}
                  filteredPlaces={filteredPlaces}
                  setViewRegion={setViewingRegion}
                  setSelectedPlace={setSelectedPlace}
                  searchResults={searchResults}
                  />
              </Box>
          </Stack>
          </Box>
          { fullscreenMap && (
            <Box height={drawerBleeding} minHeight={drawerBleeding} flex={0}><PlaceDrawer map={map} filterPlaces={filteredPlaces} onSelectPlace={openPlaceCard} onFilterPlaces={filterViewPlaces} /></Box>
            )
          }
        </Stack>
      </Box>
      </>
  );
}