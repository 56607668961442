import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import Stack from '@mui/material/Stack'

import { UserContext } from "../context/userContext"
import { Box, Container, Grid, Typography } from "@mui/material"
import BottomNav from "../components/bottomNav"
import { coreTheme } from "../styles/themes"

export default function LandingPage() {
    const context = useContext(UserContext)
    const navigate = useNavigate()

    if (!context?.loadingUser) {
        if (context?.user) {
            navigate("/mymaps")
        }    
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} paddingTop={"40px"} >
                <Grid item xs={12} md={5.75} display={"flex"}>
                    <Stack justifyContent={"center"}>
                        <Typography variant="h1">Discover, curate, and share your favorite spots all in one place</Typography>
                        <Typography variant="h2" color={coreTheme.palette.grey[800]}>Spotmap helps you give & receive personal recs from the people you trust most</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={0} md={0.5}></Grid>
                <Grid item xs={12} md={5.75}>
                    <img src="/placeholder.png" style={{maxWidth: "100%"}}/>
                </Grid>
                <Grid item xs={12}>
                    <Box alignItems={"center"} alignSelf={"center"} paddingTop={"40px"}>
                        <Typography textAlign="center" variant="h1">Create your spotmap</Typography>
                        <Typography textAlign="center" variant="h5" color={coreTheme.palette.grey[800]}>Make your own</Typography>
                    </Box>
                </Grid>
                <Grid item xs={4} paddingTop={"40px"}>
                    <Typography textAlign="center" variant="h5" fontWeight={"bold"}>Choose your faves</Typography>
                </Grid>
                <Grid item xs={4} >
                    <Typography textAlign="center" variant="h5" fontWeight={"bold"}>Personalize it</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography textAlign="center" variant="h5" fontWeight={"bold"}>Share the link</Typography>
                </Grid>
                <Grid  item xs={4} flex={1} display={"flex"} justifyContent={"center"} >
                    <img src="/place_image.png"  style={{width:'100%', maxWidth:'200px'}}/>
                </Grid>
                <Grid item xs={4} flex={1} display={"flex"} justifyContent={"center"} >
                    <img src="/emoji_image.png"  style={{width:'100%', maxWidth:'200px'}}/>
                </Grid>
                <Grid item xs={4}  flex={1} display={"flex"} justifyContent={"center"}>
                    <img src="/share_image.png"  style={{width:'100%', maxWidth:'200px'}}/>
                </Grid>
                <Grid item xs={12}>
                    <BottomNav/>
                </Grid>
            </Grid>
        </Container>
    )
}